import React from 'react'

const WaitlistTooltip: React.FC = () => {
  return (
    <div
      className={
        'flex animate__fadeIn flex-row items-center justify-start sm:justify-between w-full px-2 py-4 bg-white rounded-[16px] shadow-md'
      }
      style={{
        border: '1px solid #78FD85',
      }}
    >
      <div className={'text-[20px] px-2 h-full'}>✅</div>
      <div className='flex flex-col px-2'>
        <div className={'text-[16px] font-bold text-start'}>You joined Waitlist</div>
        <div className={'text-[12px] text-start'}>
          We will send you an email when beta is ready
        </div>
      </div>
    </div>
      )
}

export default WaitlistTooltip