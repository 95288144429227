import React from 'react'
import CVVC from '../../../assets/svg/cvvc.svg'
import SwissFlag from '../../../assets/svg/swiss-flag.svg'
import Stripe from '../../../assets/svg/stripe.svg'
import { Markup } from 'interweave'

const companies = [
  {
    img: SwissFlag,
    title: 'Company R No 849373008',
    description: 'Purpose Group SA, Sentier de Sales 9, Geneve 1205',
  },
  {
    img: CVVC,
    title: 'Investment <br/> partner',
  },
  {
    img: Stripe,
    title: 'Financial  <br/>  partner',
  },
]

const CompanyList: React.FC = () => {
  const CompanyTile = ({ company }: any) => {
    return (
      <div className='h-6 justify-start items-center flex-grow gap-2 flex min-w-1/2'>
        <img alt={`${company.title}`} src={company.img} />
        <div className={'flex flex-col items-start justify-start'}>
          <Markup className='text-black text-[10px] leading-[12px] font-bold text-start' content={company.title} />
          {company.description && (
            <Markup
              className='text-black text-[10px] leading-[12px] font-normal text-start truncate'
              content={company.description} />
          )}
        </div>
      </div>
    )
  }
  return (
    <div
      className={'flex flex-row flex-wrap items-center gap-4 justify-evenly sm:px-4 px-[32px] flex-grow w-full bg-white sm:py-8 py-[40px] '}>
      {companies.map((company) => {
        return <CompanyTile key={`key:${company.title}`} company={company} />
      })}
    </div>
  )
}

export default CompanyList