import React from 'react'
import KevinBg from '../../../assets/img/Kevin-bg.png'
import Kevin from '../../../assets/img/kevin.png'
import LogoGreen from '../../../assets/svg/logo-green.svg'

const KevinPyrpose = () => {
  return (
    <div style={{
      backgroundImage: `url(${KevinBg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
         className={'flex w-full justify-between'}
    >
      <div className={'w-1/2 flex justify-start items-end relative'}>
        <img src={Kevin} alt={'kevin'} className={'absolute left-0 bottom-0 bg-cover'} />
      </div>
      <div className='w-1/2 flex-col justify-start items-start inline-flex py-[32px]'>
        <img alt={'logo'} src={LogoGreen}  className={'ml-[-18px] mb-[-8px] w-1/2'}/>
        <div className='self-stretch text-zinc-900 text-xs font-normal text-start'>Talk to as many people as possible about
          your product.<div className={'h-4'}/> You will always learn something that helps you to deliver your vision and
          your market fit
        </div>
        <div className=' text-zinc-900 text-xs font-bold text-start pt-2'>Kevin Kyer<br />CEO and Co-Founder<br />of
          Pyrpose.io
        </div>
      </div>
    </div>
  )
}

export default KevinPyrpose