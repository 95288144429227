import React, { memo } from 'react'
import './styles.scss'
import { useNavigate } from 'react-router-dom'
import Train from '../../assets/img/train.png'
import Food from '../../assets/img/food.png'
import Dress from '../../assets/img/dress.png'
import And from '../../assets/svg/and.svg'
import { Typography } from '@mui/material'

const HowToReduce = () => {
  const navigate = useNavigate()

  const handleOffset = () => {
    navigate('/how-it-works')
  }

  return (
    <div
      className={'w-full h-full min-h-screen flex flex-col items-center justify-center'}
      style={{
        background:
          'radial-gradient(circle, #8EF392 0%, #83ED79 28.65%, #87E6EC 79.69%, #94E7F9 100%)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <br />
      <div className='flex flex-col items-center justify-evenly sm:w-2/3 md:w-1/3 w-full px-4'>
        <Typography variant='body1' className={'!font-bold !text-[32px] text-center mt-4 '}>
          How I can reduce CO2?
        </Typography>
      </div>
      <br />
      <div className='flex flex-col'>
        <div className='info-panel' style={{ backgroundImage: `url(${Train})` }}>
          <div className='info-title'>
            <Typography
              variant='body1'
              style={{
                fontSize: '16px',
                lineHeight: '24px',
                textAlign: 'center',
                color: '#FFFFFF',
              }}
            >
              Use trains instead of planes
            </Typography>
          </div>
        </div>
        <br />
        <div className='info-panel' style={{ backgroundImage: `url(${Food})` }}>
          <div className='info-title'>
            <Typography
              variant='body1'
              style={{
                fontSize: '16px',
                lineHeight: '24px',
                textAlign: 'center',
                color: '#FFFFFF',
              }}
            >
              Eat less meat and choose organic and local
            </Typography>
          </div>
        </div>
        <br />
        <div className='info-panel' style={{ backgroundImage: `url(${Dress})` }}>
          <div className='info-title'>
            <Typography
              variant='body1'
              style={{
                fontSize: '16px',
                lineHeight: '24px',
                textAlign: 'center',
                color: '#FFFFFF',
              }}
            >
              Don’t buy fast fashion
            </Typography>
          </div>
        </div>
        <br />
        <img src={And} alt={'and'} />
      </div>
      <div className='flex flex-col'>
        <div className='offset-panel' onClick={handleOffset}></div>
      </div>
    </div>
  )
}

export default memo(HowToReduce)
