interface Config {
  env?: string
  apiEndpoint?: string
  staticAssets?: string
  surveyEndpoint?: string
  reactGAKey?: string
  crossmintClientId?: string
  fbPixel?: string
  currentAbTest?: string
}

console.log('process.env', JSON.stringify(process.env, null, 4))

const config: Config = {
  env: process.env.REACT_APP_ENV,
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT,
  staticAssets: (process.env.REACT_APP_API_ENDPOINT) + '/assets/',
  surveyEndpoint: process.env.REACT_APP_SURVEY_ENDPOINT,
  reactGAKey: process.env.REACT_APP_MEASUREMENT_ID,
  crossmintClientId:
  process.env.REACT_APP_CROSSMINT_CLIENT_ID,
  fbPixel: process.env.REACT_APP_FB_PIXEL,
  currentAbTest: 'AB_CTA',
}

export default config
