import React from 'react'
import { Markup } from 'interweave'

interface QuoteItemProps {
  quote: string
  mainImage?: string
  authorImage: string
  authorName: string
}

const QuoteItem: React.FC<QuoteItemProps> = ({
  quote,
  mainImage,
  authorImage,
  authorName,
}) => {
  return (
    <div className='w-full justify-start items-center gap-4 inline-flex'>
      <div className='w-1/3 h-full relative'>
        <img alt='authorImage' className=' w-full h-full bg-contain'
             src={authorImage} />
        {mainImage && <img alt='mainImage' className='w-10 h-10 right-[10px] bottom-[27px] absolute rounded-full'
                           src={mainImage} />}
      </div>
      <div className='grow shrink basis-0 flex-col justify-start items-start gap-4 inline-flex w-2/3'>
        <div className='w-full self-stretch text-start text-black text-base font-normal leading-snug'>{quote}
        </div>
        <Markup className='self-stretch text-start text-black text-[12px] font-bold' content={authorName} />
      </div>
    </div>
  )
}

export default QuoteItem
