import React from 'react'
import LogoSvg from '../../../assets/svg/logo_intro.svg'
import LogoText from '../../../assets/svg/logo-text.svg'
import Back from '../../../assets/svg/back.svg'
import Retake from '../../../assets/svg/retake.svg'
import Share from '../../../assets/svg/share.svg'
import CoverEmissionButton from './CoverEmissionButton'

interface HeaderProps {
  setStep: (step: number) => void
  handleRetake: () => void
  handleShare: () => void
  step: number
  showFriends: number
}

const Header: React.FC<HeaderProps> = ({
  setStep,
  handleRetake,
  handleShare,
  step,
  showFriends,
}) => {
  return (
    <div
      className={'flex flex-row w-full justify-between items-center py-6 px-4 top-0 right-0 left-0 sm:z-[100]'}>
      <div className={'flex items-center justify-start max-sm:hidden'}>
        <div className={'w-[24px] h-[34px]'}>
          <img src={LogoSvg} alt='logo' className={'h-full w-full'} />
        </div>
        <div className={'flex justify-center h-full'}>
          <img src={LogoText} alt='logo' className={'w-[75.82px] h-[16.65px]'} />
        </div>
      </div>
      <div
        className={'flex flex-row items-center  justify-between sm:justify-end  gap-2 max-sm: w-full'}
      >
        {step === 1 ? (
          <div
            onClick={() => setStep(0)}
            className={'flex flex-row items-center justify-center cursor-pointer'}
          >
            <img src={Back} alt={'Back'} />
            <div className={'text-[16px] ml-2'}>Back</div>
          </div>
        ) : (
          <div
            onClick={handleRetake}
            className={'flex flex-row items-center justify-center cursor-pointer'}
          >
            <img src={Retake} alt={'Retake'} />
            <div className={'text-[16px] ml-2'}>Retake</div>
          </div>
        )}

        <div
          onClick={handleShare}
          className={'flex flex-row items-center justify-center cursor-pointer sm:pr-8'}
        >
          <div className={'text-[16px] mx-2  '}>Share</div>
          <img src={Share} alt={'Share'} />
        </div>
        {/* { step === 0 && */}
        {/* <div className={'flex items-center justify-center  max-sm:!hidden'}> */}
        {/*   <CoverEmissionButton setStep={setStep} showFriends={showFriends} big={false} /> */}
        {/* </div> */}
        {/* } */}
      </div>
    </div>
  )
}

export default Header