import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Button, CircularProgress, Slider } from '@mui/material'
import Arrow from '../../../assets/svg/arrow-black.svg'
import { commaSeparator } from '../utils/calculation'
import { CrossmintPayButton } from './PayButton'
import { useQuery } from 'react-query'
import { checkCode } from '../../../utils/api'
import config from '../../../config'

interface IReductionBlock {
  reduceValue: number
  setReduceValue: (value: number) => void
  // eslint-disable-next-line
  reductionOptions: any
  reduceEmission: number
  setStep: (value: number) => void
  showFriends: number
  setSessionId: (sessionId: string | null) => void
  sessionId: string | null
  handleClick: () => void
  showValidateModal: boolean
  handleError: (message: string) => void
  reducePercentage: number | null | string
  returnPercentage: number
}

const ReductionBlock: React.FC<IReductionBlock> = ({
  reduceValue,
  setReduceValue,
  reductionOptions,
  reduceEmission,
  setSessionId,
  handleClick,
  showValidateModal,
  handleError,
  reducePercentage,
  returnPercentage,
}) => {
  const money = reductionOptions.values[reduceValue - 1]?.money
  const moneyReturn = money ? Number(money) * returnPercentage : 0
  const totalMoney = money ? money + moneyReturn : 0
  const accessCode = localStorage.getItem('accessCode') || ''
  const machineId = localStorage.getItem('machineId') || ''
  const { data, isLoading, isError } = useQuery(['access-code'], () => {
      return checkCode(accessCode)
    },
    {
      enabled: !!accessCode,
      refetchOnWindowFocus: false,
      retry: false,
    })

  return (
    <div className={'h-full w-full flex items-center flex-col justify-between sm:justify-center'}>
      <span className={'text-center font-bold text-[40px] max-sm:mt-4'}>
        <span className={'text-[#989898]'}>$</span>
        {commaSeparator(money)}
      </span>
      {reductionOptions?.labelMin && reductionOptions?.labelMax && (
        <Box className='w-full flex flex-row justify-between items-center sm:pt-6 pt-2 pb-2 '>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '24px',
            }}
          >
            {reductionOptions.labelMin}
          </Typography>
          <span className={'flex-row text-[14px] truncate px-2'}>
            <span className={'font-bold'}>{commaSeparator(reduceEmission.toFixed(2))}</span> CO2
            tones will be removed
          </span>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '24px',
            }}
          >
            {reductionOptions.labelMax}
          </Typography>
        </Box>
      )}
      <div className={'w-[90%] flex items-center justify-center'}>
        <Slider
          style={{
            padding: '0px 20px',
            borderRadius: 4,
            height: 40,
          }}
          className={'!w-full !h-[40px] !rounded-[4px] !'}
          aria-label='ios slider'
          step={reductionOptions.step}
          min={reductionOptions.min}
          max={reductionOptions.max}
          marks={reductionOptions.max / reductionOptions.step < 10}
          value={reduceValue}
          onChange={(e, val) => {
            setReduceValue(val as number)
          }}
          sx={{
            '& .MuiSlider-thumb': {
              height: 40,
              width: 40,
              marginRight: '-25px',
              marginLeft:
                reduceValue === reductionOptions.min
                  ? '-6px'
                  : window.innerWidth > 768
                    ? '0.7%'
                    : '0px',
              borderRadius: '25px',
              backgroundColor: '#78FD85',
              boxShadow: '0px 0px 0px 0px rgba(120,253,133,0.3)',
              border: '0px solid #78FD85',
              borderTopRightRadius: '25px',
              borderBottomRightRadius: '25px',
              overflow: 'hidden',
              '&:focus, &:hover, &$active': {
                boxShadow: '0px 0px 0px 0px #000 !important',
              },
            },
            '& .MuiSlider-thumb:hover': {
              boxShadow: '0px 0px 0px 0px #000 !important',
            },
            '& .MuiSlider-thumb:after': {
              content: '""',
              position: 'absolute',
              width: 40,
              height: 40,
              borderRadius: '50%',
              borderWidth: '4px',
              borderStyle: 'solid',
              borderColor: '#78FD85',
              backgroundColor: '#fff',
            },
            '& .MuiSlider-thumb:before': {
              boxShadow: '0px 0px 0px 0px #000 !important',
            },
            '& .MuiSlider-track': {
              height: 40,
              borderTopLeftRadius: 25,
              borderBottomLeftRadius: 25,
              border: 'none',
              left: '-5.6% !important',
              paddingRight: 20 + '!important',
              width: `${
                ((reduceValue - reductionOptions.min) /
                  (reductionOptions.max - reductionOptions.min)) *
                100 +
                6
              }%  !important`,
              backgroundColor: '#78FD85',
            },
            '& .MuiSlider-rail': {
              border: 'none',
              backgroundColor: '#000',
              borderRadius: 25,
              left: '-5%',
              width: '108%',
              opacity: 1,
            },
            '& .MuiSlider-mark': {
              backgroundColor: '#fff',
              height: 8,
              width: 8,
              borderRadius: '50%',
              '&.MuiSlider-markActive': {
                opacity: 1,
                backgroundColor: '#78FD85',
              },
            },
          }}
        />
      </div>
      <div className={'animate__shakeX w-full flex items-center justify-center sm:mt-4 mt-2'}>
        <img src={Arrow} alt={'arrow'} className={'animate__shakeX animate__animated'} />
      </div>
      <div className={'w-full flex items-center justify-center sm:mt-4 mt-2'}>
        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '24px',
            textAlign: 'center',
          }}
          className={'text-center'}
        >
          {reductionOptions.values[reduceValue - 1]?.title}
        </Typography>
      </div>
      <div className={'flex flex-col w-full gap-4 pb-8 pt-6'}>
        <div className={'flex flex-row justify-between w-full items-center'}>
          <Typography
            sx={{
              fontSize: '16px',
              lineHeight: '24px',
            }}
          >
            Your commitment:
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '24px',
            }}
          >
            ${money}
          </Typography>
        </div>
        <div className={'flex flex-row justify-between w-full items-center'}>
          <Typography
            sx={{
              fontSize: '16px',
              lineHeight: '24px',
            }}
          >
            Pyrpose reward:
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '24px',
            }}
          >
            + ${moneyReturn.toFixed(2)}
          </Typography>
        </div>
        <div className={'flex flex-row justify-between w-full items-center'}>
          <Typography
            sx={{
              fontSize: '17px',
              fontWeight: 700,
              lineHeight: '24px',

            }}
          >
            Projected value:
          </Typography>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 700,
              lineHeight: '28px',
              color: '#28C537',
            }}
          >
            ${totalMoney.toFixed(2)}
          </Typography>
        </div>
      </div>
      <div className={'w-full flex items-center justify-center sm:mt-4 mt-2 '}>
        {accessCode && !!data && !isError ? (
          <CrossmintPayButton
            disabled={isLoading}
            clientId={config?.crossmintClientId || ''}
            setSessionId={setSessionId}
            accessCode={accessCode}
            reducePercentage={reducePercentage}
            validateAccessCode={false}
            money={money.toFixed(0)}
            mintConfig={{
              type: 'erc-721',
              totalPrice: `${money.toFixed(0)}`,
              _price: money.toFixed(0),
              _quantity: 1,
              _clientId: config.crossmintClientId,
              userId: machineId,
              internalClientId: machineId,
            }}
            handleError={handleError}
            environment={config.env === 'production' ? 'production' : 'staging'}
            whPassThroughArgs={{
              internalClientId: machineId,
              totalPrice: Number(money.toFixed(0)),
            }}
          />
        ) : (
          <Button
            onClick={handleClick}
            variant='contained'
            className={'w-full !sm:mt-10 !font-bold'}
            disabled={showValidateModal || isLoading}
            style={{
              backgroundColor: '#000',
              color: '#fff',
              opacity: (showValidateModal && isLoading) ? 0.5 : 1,
              borderRadius: '16px',
              height: '64px',
              fontSize: '24px',
              lineHeight: '29px',
              textTransform: 'none',
              boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.25)',
              border: '1px solid #000',
            }}
          >
            {isLoading ? (<CircularProgress color={'inherit'} size={24} />) :
              'Commit'}
          </Button>
        )}
      </div>
    </div>
  )
}

export default ReductionBlock
