import React, { memo } from 'react'
import './styles.scss'
import { useNavigate } from 'react-router-dom'
import MiddleBg from '../../assets/svg/share_middle_bg.svg'
import Factory from '../../assets/svg/factory.svg'
import Smoke from '../../assets/svg/smoke.svg'
import SmokeP from '../../assets/svg/smoke_p.png'
import { Button, Typography } from '@mui/material'

const HowItWorks = () => {
  const navigate = useNavigate()

  const handleJoinWaitList = () => {
    navigate('/join-wait-list')
  }

  return (
    <div
      className={'w-full h-full min-h-screen flex flex-col items-center justify-center'}
      style={{
        backgroundColor: '#B1F4F0',
      }}
    >
      <div
        style={{ height: 'auto', position: 'absolute', top: '10%' }}
        className='flex flex-col items-center justify-evenly sm:w-2/3 md:w-1/3 w-full px-4'
      >
        <Typography variant='body1' className={'!font-bold !text-[32px] text-center mt-4 '}>
          How Pyrpose works
        </Typography>
      </div>

      {/* <div className="w-full" style={{flexGrow: 1}}></div>*/}
      <div
        className='absolute bottom-[45%] left-0 right-0'
        style={{ backgroundImage: `url(${MiddleBg})`, height: '180px', marginBottom: '-1px' }}
      ></div>
      <div
        className='absolute top-[55%] left-0 right-0'
        style={{ backgroundColor: '#90BF98', height: '45%' }}
      ></div>

      <div
        className={'w-full flex flex-row items-center justify-center'}
        style={{
          position: 'absolute',
          bottom: '46%',
          alignItems: 'stretch',
          height: '247px',
          alignContent: 'stretch',
        }}
      >
        <div
          style={{
            backgroundImage: `url(${SmokeP})`,
            height: '121px',
            backgroundRepeat: 'repeat',
            flexGrow: 1,
          }}
        ></div>
        <div
          style={{
            width: '303px',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <img
            src={Smoke}
            alt={'Smoke'}
            style={{ height: 'auto', width: 'auto', position: 'absolute', right: '57px' }}
          />
        </div>
        <div
          style={{
            flexGrow: 1,
          }}
        ></div>
      </div>

      <img
        src={Factory}
        alt={'ShareIllustration'}
        style={{ height: 'auto', position: 'absolute', bottom: '46%' }}
      />

      <div
        style={{ height: 'auto', position: 'absolute', bottom: '30%' }}
        className='flex flex-col items-center justify-evenly sm:w-2/3 md:w-1/3 w-full px-4'
      >
        <Typography variant='body1' className={'!font-bold !text-[18px] text-center mt-4 '}>
          For as little as $100 you can reduce carbon emissions and get a return
        </Typography>
      </div>
      <div
        style={{ height: 'auto', position: 'absolute', bottom: '5%' }}
        className='flex flex-col items-center justify-evenly sm:w-2/3 md:w-1/3 w-full px-4'
      >
        <Button
          variant='contained'
          onClick={handleJoinWaitList}
          className={'w-full px-4 mt-6 h-[61px]'}
          sx={{
            borderRadius: '15px',
            backgroundColor: '#000000',
            color: '#FFFFFF',
            fontWidth: 'bold',
            fontSize: 24,
            marginTop: '24px',
          }}
        >
          🌿 I want to support
        </Button>
      </div>
    </div>
  )
}

export default memo(HowItWorks)
