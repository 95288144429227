import React, { useState, useEffect, useRef, FC } from 'react'

interface IAnimatedNumber {
  value: number
  duration: number
}

export const AnimatedNumber: FC<IAnimatedNumber> = ({ value, duration }) => {
  const [displayValue, setDisplayValue] = useState(0)
  const startValue = useRef(0)
  const startTime = useRef<number | null>(null)

  useEffect(() => {
    startValue.current = displayValue
    startTime.current = performance.now()

    function animateNumber(timestamp: number) {
      if (startTime.current === null) startTime.current = timestamp
      const elapsed = timestamp - startTime.current
      const progress = Math.min(elapsed / duration, 1)

      const newValue = startValue.current + (value - startValue.current) * progress
      setDisplayValue(newValue)

      if (progress < 1) {
        requestAnimationFrame(animateNumber)
      } else {
        startTime.current = null
      }
    }

    requestAnimationFrame(animateNumber)
  }, [value, duration])

  return (
    <div className={'flex flex-col items-center justify-center mb-[-16px]'}>
      <div
        className={'font-black sm:text-[136px] text-[96px] sm:leading-[110px] leading-[84px] sm:mb-[-12px] mb-[-6px] '}
        style={{
          background: 'linear-gradient(180deg, #B3C7CB 10%, #BDD0D5 55.87%, #F7F8F9 100.61%)',
          WebkitBackgroundClip: 'text',
          color: 'transparent',
          fontFamily: 'Inter',
        }}
      >
        {displayValue.toFixed(0)}
      </div>
      <div
        className={'font-black sm:text-[56px] text-[46px] sm:leading-[32px] leading-[28px] '}
        style={{
          background: 'linear-gradient(180deg, #B3C7CB -9.41%, #BDD0D5 53.62%, #F7F8F9 107.78%)',
          WebkitBackgroundClip: 'text',
          color: 'transparent',
          fontFamily: 'Inter',
        }}
      >
        trees
      </div>
    </div>
  )
}
