import React from 'react'
import { Typography } from '@mui/material'
import IntroImage from '../../assets/svg/intro-bg.svg'

export const GDPR: React.FC = () => {
  return (
    <div
      className={'w-full min-h-screen h-full flex items-start justify-center bg-white bg-cover '}
      style={{ backgroundImage: `url(${IntroImage})`, overflow: 'auto' }}
    >
      <div className='container flex justify-center relative h-full   px-4'>
        <div className='flex flex-col items-center justify-evenly sm:w-2/3 md:w-10/12 w-full h-full'>
          <div className={'flex flex-col items-center  justify-start w-full h-full'}>
            <div className={'flex flex-col mb-12 '} style={{ textAlign: 'left' }}>
              <br />
              <Typography variant='body1' className={'!font-bold !text-[32px] text-center mt-4 '}>
                🥑 pyrpose privacy policy
              </Typography>

              <Typography>
                With the following information, PYRPOSE AG ( (hereinafter called Pyrpose or we)
                provides customers with an overview of how their personal data is processed by
                Pyrpose and their rights under data protection law. What data is processed in detail
                and how it is used very much depends on the services requested or agreed in each
                case. Customers are also requested to disclose information to current and future
                authorised representatives and beneficial owners. These include beneficiaries in the
                event of death or authorised signatories, for example.
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[32px] text-center mt-4 '}>
                1. Data processing controller
              </Typography>

              <Typography variant='body1'>
                The controller is: <br />
                Pyrpose AG <br />
                Data Protection Department <br />
                ADDRESS <br />
                Zurich, Switzerland <br />
                E-MAIL <br />
                gdpr@pyrose.io
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[32px] text-center mt-4 '}>
                2. Personal data processing
              </Typography>

              <Typography variant='body1'>
                Pyrpose processes personal data that it receives from its customers in the context
                of the business relationship. This is the case when customers come into contact with
                Pyrpose (e.g. as an interested individual, an applicant or a customer and
                particularly when customers are interested in Pyrpose’s products, sign up for online
                services or contact Yova by email, phone or application, as well as when they use
                the products and services as part of an active business relationship). In all these
                cases, Yova collects, stores, uses, transfers or deletes personal data.
                <br />
                To the extent necessary for the provision of the service, Pyrpose also processes
                personal data that it has received from other companies, such as Intrum AG, or from
                other third parties (Pyrpose’s other service providers) in a permissible manner
                (e.g. for the execution of orders, for the performance of agreements or based on
                consent given by customers). On the other hand, Pyrpose processes personal data that
                it has permissibly obtained from publicly accessible sources (e.g. land registers,
                commercial registers and registers of associations, Federal Gazette, press, media,
                internet) and that it was entitled to process.
                <br />
                <br />
                In certain cases, Pyrpose collects personal data from potential customers and
                interested individuals.
                <br />
                <br />
                To the extent necessary, Pyrpose shall also collect personal data from people who
                have no direct connection with it and who, for example, belong to one of the
                following groups of people:
                <br />
                <br />
                ✅ Family members
                <br />
                ✅ Co-applicants
                <br />
                ✅ Legal representatives (authorised signatories)
                <br />
                ✅ Customers’ beneficiaries
                <br />
                ✅ Customers’ beneficial owners
                <br />
                ✅ Shareholders
                <br />
                ✅ Representatives of legal entities
                <br />
                ✅ Employees of service providers or trading partners
                <br />
                <br />
              </Typography>
              <Typography variant='body1' className={'!font-bold !text-[20px] text-center mt-4 '}>
                2.1. Personal data may be collected, processed and stored during the conclusion and
                use of products / services.
              </Typography>
              <Typography>
                Pyrpose processes the following personal data:
                <br />
                <br />
                ✅ Identity information: E.g. first name and last name, ID card or passport number,
                nationality, place and date of birth, gender, photograph, IP address
                <br />
                ✅ Contact information: Address, email address and phone number
                <br />
                ✅ Tax information: Tax identification number, tax status
                <br />
                ✅ Banking, financial and transactional data: E.g. bank details (IBAN), money
                transfers to the Customer’s (safe custody) account, assets, investor profile
                communicated ✅ Data on habits and preferences: IP addresses, data concerning the
                use of Pyrpose’s products and services in relation to banking, financial and
                transactional data, data concerning interactions between the Customer and Pyrpose
                (visits to Pyrpose’s website, face-to-face meetings, phone calls, chat histories,
                email traffic, surveys)
                <br />
                ✅ Data concerning sustainability preferences: Preference for sustainable corporate
                focuses (handprint), preference for sustainable business practices (footprint),
                exclusion criteria, excluded companies (blacklist), desirable companies (whitelist)
                <br />
                ✅ Securities transaction: Information about knowledge and/or experience with
                financial instruments, the Customer’s risk tolerance, information about education
                and profession (e.g. level of education, occupation, name of the employer, wages,
                financial situation including the ability to bear losses (assets, liabilities,
                income, e.g. from employment / self-employment / business; expenses), foreseeable
                changes in financial circumstances (e.g. retirement age, children’s education),
                specific goals / significant concerns for the future (e.g. planned purchases,
                redemption of liabilities), marital status and family situation, documentation data
                (e.g. declarations of suitability)
                <br />
                ✅ Interest rate, currency and liquidity management: Information about knowledge
                and/or experience with interest rate / currency products / financial investments,
                investment behaviour / strategy (scope, frequency, risk tolerance), occupation,
                financial situation (assets, liabilities, income, e.g. from employment /
                self-employment / business; expenses), foreseeable changes in financial
                circumstances (e.g. retirement age, children’s education), specific goals /
                significant concerns for the future (e.g. planned purchases, redemption of
                liabilities), tax information, documentation data (e.g. declarations of suitability)
                <br />
                ✅ Customer contact information: Further personal data (e.g. information about the
                contact channel, date, occasion and result, (electronic) copies of correspondence
                and information about participation in direct marketing measures, as well as details
                of the Customer’s interests and requirements that they have expressed to Pyrpose
                shall be generated in the context of the business initiation phase and during the
                business relationship, particularly through personal, over-the-phone or written
                contacts, initiated by the Customer or by Pyrpose
                <br />
                ✅ Audiovisual data: Information from the video identification procedure, recordings
                of calls
                <br />
                ✅ Personal data relating to racial or ethnic origin, political beliefs, religious
                or philosophical beliefs, trade union membership, as well as genetic data, biometric
                data uniquely identifying a natural person, health data or data relating to a
                natural person’s sex life or sexual orientation shall not be processed by Pyrpose as
                a matter of principle, if it is not required for the payment of the church tax or if
                it is a copy of the identification required by Pyrpose due to obligations under the
                Money Laundering Act.
                <br />
                <br />
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[20px] text-center mt-4 '}>
                2.2. During visits to our website:
              </Typography>
              <Typography variant='body1'>
                When Pyrpose’s website is accessed, information is automatically sent to Pyrpose’s
                website server by the browser used on the Customer’s terminal device / computer.
                This information is temporarily stored in what is known as a ‘log file’. The
                following information is collected without the Customer’s intervention and stored
                until it is automatically deleted:
                <br />
                <br />
                ✅The accessing computer’s (or terminal device’s) IP address
                <br />
                ✅Date and time of access
                <br />
                ✅The name and URL of the retrieved file
                <br />
                ✅The website that access is gained from
                <br />
                ✅The browser used and (if applicable) the operating system of the computer (or
                terminal device) used, as well as the name of the Customer’s access provider
                <br />
                <br />
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[20px] text-center mt-4 '}>
                2.3. Supplier data
              </Typography>
              <Typography variant='body1'>
                Pyrpose collects personal data from its suppliers in the course of working with them
                to ensure a smooth business relationship. Pyrpose collects the data of the contacts
                within the organisation (e.g. name, phone number and email address). Pyrpose also
                collects bank details so it can make payments to the suppliers. <br />
                <br />
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[20px] text-center mt-4 '}>
                2.4. Means and purpose of digital data processing on the website <br />
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[20px] text-center mt-4 '}>
                2.4.1. Our website
              </Typography>

              <Typography variant='body1'>
                ✅Ensuring that a smooth connection is established to the website pyrpose.io
                <br />
                ✅ Ensuring that our website is convenient to use
                <br />
                ✅Evaluating system security and stability, and for other administrative purposes
                <br />
                <br />
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[20px] text-center mt-4 '}>
                2.4.2. Cookies
              </Typography>
              <Typography variant='body1'>
                By using cookies, we want to optimise the use of our website www.pyrpose.io in terms
                of user-friendliness, as well as statistically record and evaluate it for the
                purpose of optimising our range of services for you.
                <br />
                <br />
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[20px] text-center mt-4 '}>
                2.4.3. Google Analytics
              </Typography>
              <Typography variant='body1'>
                With the tracking measures used, we want to ensure that our websites are designed to
                meet our customers’ needs and are continuously optimised, and we also want to
                provide a statistical evaluation of how our websites are used.
                <br />
                <br />
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[20px] text-center mt-4 '}>
                2.4.4. Google conversion tracking
              </Typography>
              <Typography variant='body1'>
                ✅We use what is known as ‘conversion tracking’ in the context of using the Google
                Ads service. When you click on an ad placed by Google, a conversion tracking cookie
                is placed on your computer / terminal device. These cookies shall cease to be valid
                after 30 days and do not contain any personal data and are therefore not used for
                personal identification purposes. The information obtained using the conversion
                cookie is used to compile conversion statistics for Ads customers who have opted to
                use conversion tracking. User data is processed with pseudonyms as part of Google’s
                marketing services. This means that Google, for example, does not store and process
                the user’s name or email address; instead, it processes the relevant data in a
                cookie-related manner within pseudonymous user profiles. In other words, from
                Google’s point of view, the ads are not managed and displayed for a specifically
                identified person, but for the cookie owner, regardless of who this cookie owner is.
                This does not apply if a user has expressly allowed Google to process the data
                without this pseudonymisation. The information collected about users by Google
                marketing services is transmitted to Google and stored on Google’s servers in the
                USA.
                <br />
                ✅The Google marketing services we use include the online advertising program
                ‘Google Ads’. In particular, we use the remarketing function within the Google Ads
                service. In the case of Google Ads, each Ads customer receives a different
                ‘conversion cookie’. Cookies cannot, therefore, be tracked using Ads customers’
                websites. The information obtained using the cookie is used to compile conversion
                statistics for Ads customers who have opted to use conversion tracking. The Ads
                customers find out the total number of users who clicked on their ad and were
                forwarded to a page featuring a conversion tracking tag. However, they do not
                receive any information that can be used to identify users personally.
                <br />
                <br />
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[20px] text-center mt-4 '}>
                2.4.5. Facebook pixel
              </Typography>
              <Typography variant='body1'>
                ✅Pyrpose uses the ‘Facebook pixel’ of the social network ‘Facebook’, 1601 South
                California Avenue, Palo Alto, CA 94304, USA, within its website. What are known as
                ‘tracking pixels’ are integrated on the web pages. When you visit our site, a direct
                connection is established between your browser and the Facebook server by means of
                the tracking pixel. Facebook thereby receives the information from your browser that
                our site was called up from your terminal device (to name but one example). If you
                are a Facebook user, Facebook can assign your visit to our site to your user
                account. We would like to point out that we as the operators of this website are not
                aware of the content of the data transmitted or the purposes for which it is used by
                Facebook. We can only choose what segments of Facebook users (such as age,
                interests) to display our ads to. By accessing the pixel from your browser, Facebook
                can also see whether a Facebook ad was successful, e.g. led to an online agreement.
                This allows us to track the effectiveness of Facebook ads for statistical and market
                research purposes. <br />
                ✅Please click here if you do not wish to have data collected using the Facebook
                pixel: https://www.facebook.com/settings?tab=ads#_=_. Alternatively, you can disable
                the Facebook pixel on the Digital Advertising Alliance site at the following link:
                http://www.aboutads.info/choices/.
                <br />
                <br />
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[32px] text-center mt-4 '}>
                3. Purpose of processing and legal basis
              </Typography>
              <Typography variant='body1'>
                Pyrpose processes the aforementioned personal data in accordance with the provisions
                set out in the General Data Protection Regulation (GDPR) and the Swiss Federal Act
                on Data Protection (FADP):
                <br />
                <br />
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[20px] text-center mt-4 '}>
                3.1. For the purpose of fulfilling contractual obligations (Article 6 (1) (b) of the
                GDPR):
              </Typography>
              <Typography variant='body1'>
                Personal data processing is carried out for the provision of financial services in
                the context of the performance of Pyrpose’s agreements with its customers or for the
                performance of pre-contractual measures taken at the customers’ request. The
                purposes of data processing primarily depend on the specific product (see Point 2)
                and may include (among other things) needs analyses, advice, asset management,
                investment support, and the execution of transactions. Customers can find further
                details on the purpose of data processing in the relevant contractual documents and
                terms and conditions.
                <br />
                <br />
                Pyrpose processes the personal data of individuals within its suppliers’
                organisations so it can obtain services from them. It also stores financial data so
                that it can pay for its suppliers’ services.
                <br />
                <br />
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[20px] text-center mt-4 '}>
                3.2. In the context of balancing of interests (Article 6 (1) (f) of the GDPR):
              </Typography>
              <Typography variant='body1'>
                To the extent necessary, Pyrpose shall process customer data beyond the actual
                performance of the agreement to safeguard Pyrpose’s or third parties’ legitimate
                interests. Examples: <br />
                <br />
                ✅Exercising legal claims and defence during legal disputes
                <br />
                ✅Ensuring Pyrpose’s IT security and IT operations
                <br />
                ✅Preventing crime, and particularly preventing fraud
                <br />
                ✅ Conducting video surveillance for safeguarding domiciliary rights, for collecting
                evidence in the case of robberies and fraud offences
                <br />
                ✅ Measures for building and plant security (e.g. access controls)
                <br />
                ✅ Measures to ensure domiciliary rights
                <br />
                ✅ Measures for business management and further developing services and products
                <br />
                ✅ Ensuring a smooth connection is established to the website
                <br />
                ✅ Ensuring that Pyrpose’s website is convenient to use
                <br />
                ✅Evaluating system security and stability, and
                <br />
                ✅For other administrative purposes
                <br />
                <br />
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[20px] text-center mt-4 '}>
                3.3. Based on the Customer’s consent (Article 6 (1) (a) of the GDPR):
              </Typography>
              <Typography variant='body1'>
                Insofar as the Customer has given Pyrpose consent to process personal data for
                certain purposes (e.g. transfer of data within the network or to use their data for
                certain advertising purposes), the lawfulness of this processing is given on based
                on the consent. Any consent given can be revoked at any time. Please note that the
                revocation is only effective for the future. Processing that took place before the
                revocation is not affected by the same. If Pyrpose would like to use the Customer’s
                personal data for purposes other than those mentioned above, Pyrpose shall inform
                the Customer accordingly and, if necessary, obtain the Customer’s consent.
                <br />
                <br />
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[20px] text-center mt-4 '}>
                3.4. Based on legal requirements (Article 6 (1) (c) of the GDPR) or in the public
                interest (Article 6 (1) (e) of the GDPR):
              </Typography>
              <Typography variant='body1'>
                As a financial services institution, Pyrpose is also subject to various legal
                obligations. This means that legal requirements, as well as banking supervisory
                requirements, must be met. The purposes of processing include (but are not limited
                to) verifying identity and age, preventing fraud and money laundering, ensuring
                compliance with sanctions and embargo provisions, responding to official enquiries
                from a competent governmental body or judicial authority, abiding by tax law
                monitoring and reporting obligations, and assessing and managing risks in Pyrpose.
                <br />
                <br />
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[34px] text-center mt-4 '}>
                4. Recipients of personal data belonging to customers
              </Typography>
              <Typography variant='body1'>
                Within Pyrpose, access to the Customer’s data is granted to those offices that need
                it to fulfil contractual and legal obligations. Service providers and vicarious
                agents employed by Pyrpose may also receive data for these purposes if they comply
                with banking secrecy and Pyrpose’s written instructions under data protection law.
                <br />
                <br />
                With regard to the disclosure of data to recipients outside Pyrpose, it should first
                be noted that Pyrpose is obligated to maintain secrecy about all customer-related
                facts and evaluations that it becomes aware of.
                <br />
                <br />
                Pyrpose may only pass on information about customers if doing so is required by law,
                if the Customer has given their consent, and if processors commissioned by Pyrpose
                guarantee compliance with banking secrecy and the specifications set out in the
                General Data Protection Regulation / German Federal Data Protection Act in the same
                way. Under these conditions, recipients of personal data may be (for example):
                <br />
                <br />
                ✅Public bodies and institutions if a legal or regulatory obligation exists
                <br />
                ✅Other credit and financial services institutions, comparable institutions and
                processors to whom Pyrpose transfers personal data to carry out the business
                relationship with the customers. These companies are also legally or contractually
                obligated to treat personal data with the necessary care
                <br />
                ✅Brokers
                <br />
                ✅Service providers who support Pyrpose, specifically in the following activities:
                Supporting / maintaining EDP/IT applications, archiving, call centre services,
                compliance services, controlling, data screening for anti-money laundering purposes,
                customer management, marketing, reporting, risk controlling, expense reporting,
                telephony, video identification, securities services, share register, auditing
                services, payment transactions, preparing tax data and reports
                <br />
                ✅Service providers who support Pyrpose, specifically in the following activities:
                Supporting / maintaining EDP/IT applications, archiving, call centre services,
                compliance services, controlling, data screening for anti-money laundering purposes,
                customer management, marketing, reporting, risk controlling, expense reporting,
                telephony, video identification, securities services, share register, auditing
                services, payment transactions, preparing tax data and reports
                <br />
                ✅Members of certain regulated professions such as lawyers, notaries or auditors
                <br />
                ✅Other data recipients may be those bodies that the customers have given their
                consent to data transfer for
                <br />
                <br />
                Note: Under no circumstances shall personal data be sold to third parties.
                <br />
                <br />
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[34px] text-center mt-4 '}>
                5. As a rule, data shall not be transferred to a third country or an international
                organisation
              </Typography>
              <Typography variant='body1'>
                Data is only transferred to countries outside Switzerland or the EU or the EEA
                (‘third countries’) if doing so is necessary for the execution of the Customer’s
                orders (e.g. payment and securities orders), if doing so is required by law (e.g.
                reporting obligations under tax law), if the Customer has given their consent, or
                within the scope of order processing. If service providers in third countries are
                engaged, they are obligated to comply with the level of data protection in Europe by
                agreeing to the EU standard contractual clauses in addition to written instructions.
                If you require a hard copy of these terms and conditions or information about the
                availability of the same, you may write to Pyrpose.
                <br />
                <br />
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[34px] text-center mt-4 '}>
                6. Data storage period
              </Typography>
              <Typography variant='body1'>
                Pyrpose processes and stores personal data belonging to customers for as long as
                doing so is necessary for the fulfilment of contractual and legal obligations. It
                should be noted that the business relationship is a continuing obligation that is
                intended to last for several years. If the data is no longer required for the
                fulfilment of contractual or legal obligations, it is regularly deleted, unless –
                temporary – further processing is necessary for the following purposes:
                <br />
                <br />
                ✅Fulfilment of statutory retention periods. These include obligations arising from
                the Code of Obligations, the Business Records Ordinance, the Consumer Credit Act,
                the Anti-Money Laundering Act, the Financial Institutions Act, the Financial
                Services Act and tax law. The retention and documentation periods stipulated therein
                range from up to ten years.
                <br />
                ✅Preservation of evidence under the statute of limitations. The limitation periods
                may be up to 30 years, with the ordinary limitation period being ten years.
                <br />
                A retention period until the applicant revokes their consent shall apply to
                applicants with whom an agreement is not subsequently concluded.
                <br />
                <br />
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[34px] text-center mt-4 '}>
                7. Protection of personal data
              </Typography>
              <Typography variant='body1'>
                Pyrpose shall take reasonable and adequate measures that protect stored and
                processed information from misuse, loss or unauthorised access. Pyrpose has taken a
                number of technical and organisational measures for this purpose.
                <br />
                <br />
                If you suspect that your personal information has been misused, lost or accessed
                without authorisation, please notify Pyrpose as soon as possible.
                <br />
                <br />
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[34px] text-center mt-4 '}>
                8. Data protection rights under the General Data Protection Regulation
              </Typography>
              <Typography variant='body1'>
                Every data subject has a right of access under Article 15 of the GDPR, a right to
                rectification under Article 16 of the GDPR, a right to erasure under Article 17 of
                the GDPR, a right to restriction of processing under Article 18 of the GDPR, a right
                to object under Article 21 of the GDPR, and the right to data portability under
                Article 20 of the GDPR.
                <br />
                <br />
                The right of access includes information about the purposes of processing, the
                categories of personal data, the categories of recipients to whom your data has been
                or is being disclosed, the planned duration of storage, the existence of a right to
                rectification, erasure, restriction of processing, objection or data portability,
                the existence of a right to lodge a complaint, the origin of your data if it was not
                collected by Pyrpose, and the existence of automated decision-making including
                profiling, as well as any meaningful information regarding details of the same.
                <br />
                <br />
                The Customer may (at any time) request that incorrect personal data be rectified
                immediately, or that personal data collected by Pyrpose be completed.
                <br />
                <br />
                The Customer may request that their personal data that Pyrpose stores about them be
                erased insofar as processing is unnecessary for exercising the right to freedom of
                expression and information, for fulfilling a legal obligation, for reasons of public
                interest or for establishing, exercising or defending legal claims. Pyrpose shall
                delete this data if none of the cases mentioned above apply. Pyrpose shall usually
                also include the Customer’s name in the list of people who do not wish to be
                contacted. In this way, Pyrpose minimises the chance that customers will be
                contacted in the future if their data is collected separately under other
                circumstances.
                <br />
                <br />
                Under certain circumstances, the Customer may request that Pyrpose restrict
                processing of their personal data. This means that Pyrpose shall only store the
                Customer’s data in the future and cannot carry out any further processing activities
                until: (i) one of the conditions listed below has been cleared, (ii) the Customer
                has given their consent, or (iii) further processing is necessary to assert,
                exercise or defend legal claims, to protect the rights of others, or if doing so is
                necessary due to legitimate public interest of the EU or a Member State. The
                Customer may request that Pyrpose restrict processing of their personal data under
                the following circumstances:
                <br />
                <br />
                ✅If the Customer disputes the accuracy of the personal data that Pyrpose processes
                about them. In this case, Pyrpose’s processing of the Customer’s personal data shall
                be restricted until the accuracy of the data has been verified.
                <br />
                ✅If the Customer objects to Yova’s processing of their personal data in accordance
                with Pyrpose’s legitimate interests. In this case, the Customer may request that the
                data be restricted while Yova reviews its grounds for processing the Customer’s
                personal data.
                <br />
                ✅If Pyrpose’s processing of the Customer’s data is unlawful, but the Customer
                prefers to restrict Pyrpose’s processing instead of having the data deleted.
                <br />
                ✅When there is no longer a need for Pyrpose to process the Customer’s personal
                data, but the Customer needs the data to assert, exercise or defend legal claims.
                <br />
                <br />
                The Customer may request receipt of their personal data that they provided to
                Pyrpose in a structured, commonly used and machine-readable format or transfer to
                another controller.
                <br />
                <br />
                If a decision to conclude or perform an agreement has only been made in an automated
                process (Art. 22 of the GDPR) and this decision has a legal effect on the Customer
                or the Customer is significantly affected in a similar way, the Customer may request
                that Pyrpose carry out a manual review again after they have explained their
                position to Pyrpose and requested the manual review. If such a decision is made,
                Pyrpose shall also inform the Customer separately of the reason for and the scope
                and intended effects of such data processing.
                <br />
                <br />
                The Customer also has a right to lodge a complaint. The Customer may contact the
                data protection officer in this regard on gdpr@pyrpose.io. In addition, the Customer
                can contact the supervisory authority of their usual place of residence or
                workplace, or Pyrpose’s company headquarters for this purpose.
                <br />
                <br />
                The Customer may revoke their consent to personal data processing at any time. The
                Customer is informed that their revocation is only effective for the future.
                Processing that took place before the revocation is not affected by the same. In
                this regard, please also refer to the separate notice at the end of this privacy
                policy.
                <br />
                <br />
                Pyrpose shall cease the relevant activities when the Customer objects. This shall
                apply with the exception that Pyrpose can demonstrate that it has overriding
                legitimate grounds for processing that override the Customer’s interests or that the
                data is processed to assert, exercise or defend a legal claim.
                <br />
                <br />
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[34px] text-center mt-4 '}>
                9. Obligation to provide data
              </Typography>
              <Typography variant='body1'>
                In the context of the joint business relationship, the Customer must provide such
                personal data as is required for establishing and performing a business relationship
                and fulfilling the associated contractual obligations or that Pyrpose is legally
                obligated to collect. Without this data, Pyrpose shall generally have to refuse to
                conclude the agreement or execute the order, or shall no longer be able to execute
                an existing agreement and may have to terminate it. In particular, Pyrpose is
                obligated under anti-money laundering regulations to identify the Customer before
                the business relationship is established (e.g. by means of an ID card) and to
                collect and record the Customer’s name, place of birth, date of birth, nationality,
                residential address and identification data. For Pyrpose to be able to comply with
                this statutory obligation, the Customer must provide Pyrpose with the necessary
                information and documents and immediately inform it of any changes arising in the
                course of the business relationship. If the Customer fails to provide Pyrpose with
                the necessary information and documents, Pyrpose may not enter into or continue the
                business relationship requested by the Customer.
                <br />
                <br />
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[34px] text-center mt-4 '}>
                10. Automated decision-making
              </Typography>
              <Typography variant='body1'>
                As a matter of principle, Pyrpose does not use fully automated decision-making
                processes according to Article 22 of the GDPR to establish and implement the
                business relationship. If Pyrpose uses these processes in individual cases,
                customers shall be informed to this effect separately, insofar as is required by
                law.
                <br />
                <br />
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[34px] text-center mt-4 '}>
                11. Proﬁling
              </Typography>
              <Typography variant='body1'>
                To some extent, Pyrpose processes customers’ data automatically with the aim of
                assessing certain personal aspects (proﬁling). In so doing, Pyrpose uses proﬁling in
                the following case, for example:
                <br />
                <br />
                Due to legal requirements, Pyrpose is obligated to combat money laundering and
                fraud. Data evaluations (e.g. in payment transactions) are also carried out. These
                measures also serve to protect customers.
                <br />
                <br />
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[34px] text-center mt-4 '}>
                12. Modification clause
              </Typography>
              <Typography variant='body1'>
                This privacy policy is currently valid and was last updated in January, 2022.
                Pyrpose reserves the right to change this privacy policy from time to time. Please
                check whether an updated version is available regularly, particularly before using a
                service. Customers can access and print out the current privacy policy from the
                website at www.pyrpose.oi. Pyrpose shall inform customers of fundamental changes on
                its website and through the usual communication channels.
                <br />
                <br />
              </Typography>

              <Typography variant='body1' className={'!font-bold !text-[34px] text-center mt-4 '}>
                Information about your right to object according to Article 21 of the General Data
                Protection Regulation (GDPR)
              </Typography>
              <Typography variant='body1' className={'!font-bold !text-[34px] text-center mt-4 '}>
                1. Individual right to object
              </Typography>
              <Typography variant='body1'>
                You have the right, on grounds relating to your particular situation, to object at
                any time to processing of the personal data concerning you based on Art. 6 (1) (e)
                of the GDPR (data processing in the public interest) and Art. 6 (1) (f) of the GDPR
                (data processing based on balancing of interests); this also applies to profiling
                under the terms of Art. 4 (4) of the GDPR. If you file an objection, we shall no
                longer process your personal data unless we can demonstrate compelling and
                legitimate grounds for processing that override your interests, rights and freedoms,
                or if processing serves to assert, exercise or defend legal claims.
                <br />
                <br />
              </Typography>
              <Typography variant='body1' className={'!font-bold !text-[34px] text-center mt-4 '}>
                2. Right to object to data processing for advertising purposes
              </Typography>
              <Typography variant='body1'>
                In individual cases, we process your personal data for the purpose of carrying out
                direct advertising. You have the right at any time to object to processing of the
                personal data concerning you for the purposes of such advertising; this also applies
                to profiling if it is in conjunction with such direct advertising. If you object to
                processing for the purposes of direct advertising, we shall no longer use your
                personal data for these purposes.
                <br />
                <br />
                The objection can be made without any formalities and should, if possible, be sent
                by email to gdpr@pyrpose.ch.
                <br />
                <br />
                <br />
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
