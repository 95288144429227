import React, { type FC, memo, useCallback, useEffect, useState } from 'react'
import {
  EmissionLine,
  ErrorTooltip,
  FootballField,
  LoadingModal,
  ValidateModal,
  WaitlistTooltip,
  Chart,
  InfoBlock, Header, CompanyList,
} from './components'
import { useQuery } from 'react-query'
import { addWaitlist, checkTransactionStatus, getSurveyFootprint, postFunnel, postUser } from '../../utils/api'
import { useLocation, useNavigate } from 'react-router-dom'
import quizStore from '../../stores/answers'
import ReactGA from 'react-ga4'
import { AnimatedNumber } from './components/AnimatedValue'
import { TextField } from '@mui/material'
import 'animate.css'
import CompareResultsModal from '../CompareResultsModal'
import ShareLinkModal from '../ShareLinkModal'
import ReductionBlock from './components/ReductionBlock'
import { ComparisonSwitcher } from './components/ComparisonSwitcher'
import { commaSeparator, getFields, toFootballFields } from './utils/calculation'
import LoaderPulse from '../../components/LoaderPulse'
import FootballFields from './components/FootballFields'
import Typography from '@mui/material/Typography'
import Modal from '../../shared/Modal'
import PrimaryButton from '../../components/PrimaryButton'
import { Form, Formik } from 'formik'
import { animated, useSpring } from '@react-spring/web'
import ReactPixel from 'react-facebook-pixel'
import { compareEmissions } from './components/EmissionLine'
import CoverEmissionButton from './components/CoverEmissionButton'
import Footer from '../Landing/components/Footer'
import TreesToFootball from '../../assets/svg/trees-to-football.svg'
import CommunityBlock from '../../assets/svg/how-community-works.svg'
import DifferentBlock from '../../assets/svg/different-block.svg'
import { treesToField } from '../../constants/values'

const Results: FC = () => {
  // eslint-disable-next-line
  const [result, setResult] = useState<any>(null)
  const [showFriends, setShowFriends] = useState<number>(0)
  // eslint-disable-next-line
  const [refState, setRefState] = useState<any>('')
  const [step, setStep] = useState<number>(0)
  const [showCompareModal, setShowCompareModal] = useState<boolean>(false)
  const [showLinkModal, setShowLinkModal] = useState<boolean>(false)
  const [sessionId, setSessionId] = useState<string | null>(null)
  const [enabled, setEnabled] = useState(false)
  const [sessionLoading, setSessionLoading] = useState(false)
  const [showValidateModal, setShowValidateModal] = useState(false)
  const [showEmailModal, setShowEmailModal] = useState<boolean>(false)
  const [tooltip, setTooltip] = useState<boolean>(false)
  const [errorTooltip, setErrorTooltip] = useState<string>('')
  const [activeStep, setActiveStep] = useState<number | null>(null)
  const machineId = localStorage.getItem('machineId')
  const email = localStorage.getItem('email')
  const accessCode = localStorage.getItem('accessCode')
  const { state } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (sessionId !== null) {
      setEnabled(true)
      setShowValidateModal(false)
      setSessionLoading(true)
    }
  }, [sessionId])

  useEffect(() => {
    ReactPixel.track('ViewContent')
  }, [])

  const { isLoading } = useQuery(
    ['getFootprint'],
    async () => {
      getSurveyFootprint(state?.ref).then((res) => {
        if (res) {
          postFunnel({
            co2: res?.myEmission,
          })
          setResult(res)
          if (res.refs) {
            setRefState(res.refs[0])
          }
        }
      })
    },
    {
      refetchOnMount: true,
    },
  )
  const reductionOptions =
    showFriends === 1
      ? refState?.prices
      : showFriends === 2
        ? refState?.combinedPrices
        : result?.prices
  const [reduceValue, setReduceValue] = React.useState<number>(reductionOptions?.min || 1)
  const money = reductionOptions?.values[reduceValue - 1]?.money
  const reducePercentage = reductionOptions?.values[reduceValue - 1]?.value
  const setReduceValueHandler = (value: number) => {
    ReactPixel.track('CustomizeProduct')
    setReduceValue(value)
  }


  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {
  }, [reduceValue, showFriends])
  const handleRetake = () => {
    quizStore.clearUserAnswers()
    ReactGA.event({
      category: 'RetakeQuiz',
      action: 'RetakeQuiz',
    })
    navigate('/')
  }
  const handleShare = () => {
    ReactGA.event({
      category: 'ShareLinkModal',
      action: 'Open ShareLinkModal',
    })
    if (!email) {
      setShowCompareModal(true)
    } else {
      setShowLinkModal(true)
    }

    postUser({
      sharePressed: true,
    }).then()
  }

  const handleCompareResultsModalSkip = () => {
    ReactGA.event({
      category: 'CompareResultsModal',
      action: 'Skip CompareResultsModal',
    })
    setShowCompareModal(false)
    setShowLinkModal(true)
  }
  const handleShareLinkModalSkip = () => {
    setShowLinkModal(false)
    ReactGA.event({
      category: 'ShareLinkModal',
      action: 'Skip ShareLinkModal',
    })
    setShowLinkModal(false)
    // navigate('/take-an-action')
  }

  const reduceEmissionRef =
    showFriends === 1
      ? refState?.myEmission
      : showFriends === 2
        ? refState?.combinedEmission
        : result?.myEmission
  const reduceEmission = reduceEmissionRef * reductionOptions?.values[reduceValue - 1]?.value
  const reduceField = toFootballFields(reduceEmission)
  const reduceFields = getFields(reduceField)

  const handleSuccess = useCallback(() => {
    ReactGA.event({
      category: 'CompareResultsModal',
      action: 'CompareResultsModal Success',
    })
    navigate('/results/success', {
      state: {
        reduceEmission,
        reduceField,
        reduceFields,
        reduceValue: reductionOptions?.values[reduceValue - 1]?.value,
      },
    })
  }, [reduceEmission, reduceField, reduceFields, reduceValue, reductionOptions?.values])

  // eslint-disable-next-line
  const { isLoading: statusLoading } = useQuery(
    ['transactionStatus', sessionId],
    () => checkTransactionStatus(sessionId),
    {
      enabled,
      retry: false,
      refetchInterval: 5000,
      onError: (err) => {
        if (err) {
          setEnabled(false)
          setSessionLoading(false)
        }
      },
      onSuccess: (data) => {
        if (data?.status === 'success') {
          ReactPixel.track('Purchase', {
            value: money,
            currency: 'USD',
          })
          setSessionId(null), setEnabled(false), setSessionLoading(false)
          handleSuccess()
        } else if (data?.status === 'error') {
          setSessionId(null), setEnabled(false), setSessionLoading(false)
        }
      },
    },
  )

  const [springTooltip, setSpringTooltip] = useSpring(() => ({
    opacity: '0',
    display: 'none',
  }))
  const [springErrorTooltip, setSpringErrorTooltip] = useSpring(() => ({
    opacity: '0',
    display: 'none',
  }))

  useEffect(() => {
    if (tooltip) {
      setSpringTooltip({
        opacity: '1',
        display: 'flex',
      })
      setTimeout(() => {
        setSpringTooltip({
          opacity: '0',
          display: 'none',
        })
        setTooltip(false)
      }, 3000)
    }
    if (errorTooltip) {
      setSpringErrorTooltip({
        opacity: '1',
        display: 'flex',
      })
      setTimeout(() => {
        setSpringErrorTooltip({
          opacity: '0',
          display: 'none',
        })
        setErrorTooltip('')
      }, 3000)
    }
  }, [tooltip, errorTooltip])

  const checkTransaction = async () => {
    await checkTransactionStatus(sessionId).then(
      (res) => {
        if (res?.status === 'success') {
          setSessionId(null), setEnabled(false), setSessionLoading(false)
          handleSuccess()
        } else {
          setErrorTooltip('We still waiting for your payment to be processed. Please try again later.')
        }
      },
    )
  }
  const handleCancel = () => {
    setShowValidateModal(false)
    setSessionId(null)
    setSessionLoading(false)
  }

  const comparisonValue = () => {
    switch (showFriends) {
    case 0:
      return compareEmissions(
        result?.myEmission || 0, result?.average || 0,
      ).value
    case 1:
      return compareEmissions(
        refState.myEmission || 0, result?.average || 0,
      ).value

    default:
      return compareEmissions(
        result?.myEmission || 0, result?.average || 0,
      ).value
    }
  }
  const activeComponents = () => {
    switch (step) {
    case 0:
      return (
        <>
          <div>
            {/* <div */}
            {/*   className={ */}
            {/*     'sm:text-[32px] text-[20px] mt-4 text-center font-bold sm:leading-[35px] leading-[24px] max-sm:mb-2' */}
            {/*   } */}
            {/* > */}
            {/*   To cover{' '} */}
            {/*   {refState && showFriends === 1 */}
            {/*     ? refState?.name + '`s' */}
            {/*     : showFriends === 2 */}
            {/*       ? 'total' */}
            {/*       : 'your'}{' '} */}
            {/*   annual emissions, */}
            {/*   <br /> */}
            {/*   you would need to plant: */}
            {/* </div> */}
            <div className='flex flex-col w-full items-center justify-center  '>
              <p className=' self-stretch font-bold text-black text-[40px] text-center pb-4 leading-[44px] '>
                You are {comparisonValue()} the global average
              </p>
              <p className=' self-stretch text-[20px] text-center  leading-[26px] '>
                Which is the equivalent of:
              </p>
            </div>
            <div className={'w-full flex items-center justify-center px-4 flex-col'}>
              <img src={TreesToFootball} alt='trees to football' className='w-full bg-cover' />
              <div className='flex items-start justify-between px-[16px] mt-[-44px] w-full'>
                <div className='flex flex-col items-center gap-[8px] w-[50%] justify-center'>
                  <div
                    className='self-stretch text-center text-black text-[40px] font-bold leading-[42px]'>{result?.trees}</div>
                  <div className='self-stretch text-center text-black text-base font-normal leading-snug'>Planted
                    trees
                  </div>
                </div>
                <div className='self-stretch flex flex-col items-center gap-[8px] w-[50%] justify-center'>
                  <div
                    className='self-stretch text-center text-black text-[40px] font-bold leading-[42px]'>{result?.footballFields}</div>
                  <div className='self-stretch text-center text-black text-base font-normal leading-snug'>Football
                    fields area
                  </div>
                </div>
              </div>
            </div>
            <div className={'w-full flex items-center justify-center pb-[120px] mt-[20px]'}
                 style={{ background: 'linear-gradient(180deg, #F8FAFA 0%, #ECEFF0 100%)' }}>
              <img className={' w-full '} src={CommunityBlock} alt='CommunityBlock' />
            </div>
            <div className={' pt-[45px] pb-[75px] bg-white flex flex-col  px-4'}>
              <div className=' text-center text-stone-900 text-[40px] font-bold leading-[42px] pb-[56px]'>Why we are
                different
              </div>
              <img className={' w-full bg-white'} src={DifferentBlock} alt='CommunityBlock' />
            </div>
            <Footer />
            <div className={'h-[100px] bg-white w-full'} />
            {/*   <div className={'w-full sm:mb-0px mb-[12px] mt-4'}> */}
            {/*     <AnimatedNumber */}
            {/*       value={ */}
            {/*         refState && showFriends === 1 */}
            {/*           ? refState?.trees */}
            {/*           : showFriends === 2 */}
            {/*             ? refState?.combinedTrees */}
            {/*             : result?.trees || 0 */}
            {/*       } */}
            {/*       duration={100} */}
            {/*     /> */}
            {/*   </div> */}
            {/* </div> */}
            {/* <div className={'flex flex-row flex-wrap gap-2 w-full items-end justify-center'}> */}
            {/*   {showFriends === 0 && <FootballFields fields={result?.fields} />} */}
            {/*   {showFriends === 1 && <FootballFields fields={refState?.fields} />} */}
            {/*   {showFriends === 2 && ( */}
            {/*     <Chart */}
            {/*       average={result?.average} */}
            {/*       myEmission={result?.myEmission} */}
            {/*       refEmission={refState?.myEmission} */}
            {/*     /> */}
            {/*   )} */}
          </div>
          {/* {showFriends !== 2 ? ( */}
          {/*   <div className={'sm:text-[22px] text-[20px] mt-4 text-center '}> */}
          {/*     equaling <span className={'font-bold sm:text-[22px] text-[16px]'}>  {refState && showFriends === 1 */}
          {/*     ? commaSeparator(refState?.footballFields) */}
          {/*     : commaSeparator(result?.footballFields)}</span>{' '} */}
          {/*     football fields equivalent and <span */}
          {/*     className={'font-bold sm:text-[22px] text-[16px]'}>{comparisonValue()} </span> */}
          {/*     the global average */}
          {/*   </div> */}
          {/* ) : null} */}
        </>
      )
    case 1:
      return (
        <>
          <div className={'text-[32px] mt-4 text-center font-bold leading-[35px]'}>
            Your reduction will be equivalent to:
          </div>
          <div className={'w-full'}>
            <AnimatedNumber value={reduceField * treesToField} duration={100} />
          </div>
          <div className={'flex flex-row flex-wrap gap-2  items-start justify-center'}>
            {reduceFields &&
              reduceFields?.map((field, index) => {
                const mainFields =
                  showFriends === 0
                    ? result.fields
                    : showFriends === 1
                      ? refState?.fields
                      : refState?.combinedFields
                return (
                  <div
                    key={index}
                    style={{
                      width:
                        reduceFields?.length === 1
                          ? '80%'
                          : reduceFields?.length > 18
                            ? '23%'
                            : reduceFields?.length % 2 === 0 && reduceFields?.length < 5
                              ? '48%'
                              : '30%',
                    }}
                  >
                    <FootballField
                      field={(mainFields[index] * 100).toString()}
                      trees={(field * 100).toString()}
                    />
                  </div>
                )
              })}
          </div>
          <div className={'text-[32px] mt-4 text-center font-bold'}></div>
        </>
      )
    default:
      return null
    }
  }

  const activeRightComponent = () => {
    switch (step) {
    case 0:
      return (
        <div className={'flex flex-col items-center justify-center w-full hidden'}>
          <EmissionLine
            average={result?.average || 4.14}
            myEmission={result?.myEmission?.toString() || '9.12'}
            refState={refState}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            emissionLine={showFriends === 1 ? refState?.emissions || [] : result?.emissions || []}
            showFriends={showFriends}
            setStep={setStep}
          />
          <ComparisonSwitcher
            refState={refState}
            showFriends={showFriends}
            setShowFriends={setShowFriends}
          />
        </div>
      )
    case 1:
      return (
        <div className={' flex items-center  justify-center flex-col h-full mx-4 w-[90%]'}>
          <ReductionBlock
            showValidateModal={showValidateModal}
            reduceValue={reduceValue}
            setReduceValue={setReduceValueHandler}
            reductionOptions={reductionOptions}
            reducePercentage={reducePercentage}
            returnPercentage={result?.returnPercentage || 0}
            reduceEmission={reduceEmission}
            setStep={setStep}
            showFriends={showFriends}
            setSessionId={setSessionId}
            handleError={(message: string) => setErrorTooltip(message)}
            sessionId={sessionId}
            handleClick={() => {
              ReactPixel.track('Lead')
              ReactGA.event({
                  category: 'Purchase Button',
                  action: 'Purchase button pressed',
                  label: 'Purchase button pressed',
                },
              )
              postFunnel({
                pushReduceButton: 'TRUE',
              }).then()
              setShowValidateModal(true)
            }}
          />
        </div>
      )
    default:
      return null
    }
  }
  if (isLoading) {
    return <LoaderPulse />
  }
  return (
    <div
      className={'w-full flex-1 flex max-sm:!flex-col max-sm:justify-between justify-center relative'}
      style={{
        backgroundImage:
          refState && showFriends === 0
            ? 'linear-gradient(180deg, #FFFFFF 0%, #FAFBFB 48.34%, #D8F2FF 100%)'
            : showFriends === 1
              ? 'linear-gradient(180deg, #FFFFFF 0%, #FAFBFB 48.34%, #FADEE3 100%)'
              : 'linear-gradient(180deg, #FFFFFF 0%, #EEF1F3 17.47%)',
        flexDirection: 'row',
      }}
    >
      <div
        className={
          `flex flex-col container items-center justify-center h-full   ${
            step === 0 ? 'sm:w-full sm:max-w-[512px] ' : 'sm:w-[72%] sm:max-w-[72%] w-full '
          }`
        }
      >
        <Header setStep={setStep} handleRetake={handleRetake} handleShare={handleShare} step={step}
                showFriends={showFriends} />
        {
          sessionLoading && (
            <LoadingModal checkTransaction={checkTransaction} handleCancel={handleCancel} />
          )
        }
        {showValidateModal && (
          <ValidateModal
            setShowValidateModal={setShowValidateModal}
            setShowEmailModal={setShowEmailModal}
            accessCode={accessCode}
            setSessionId={setSessionId}
            setErrorTooltip={setErrorTooltip}
            reducePercentage={reducePercentage}
            money={money}
            machineId={machineId}
          />
        )}

        <div
          className={
            'h-full max-sm:overflow-y-scroll w-full max-sm:!w-full  flex-col items-center flex sm:justify-center sm:pt-[50px] pt-[20px]'
          }
          style={{
            width: step === 0 ? '100%' : '72%',
            maxWidth: step === 0 ? '512px' : '72%',
            backgroundImage: step === 0 ? 'linear-gradient(180deg, #FFFFFF10 0%, #FFFFFF50 48.34%, #FFFFFF80 100%)' : 'none',
          }}
        >
          {activeComponents()}
        </div>
      </div>
      <div
        className={
          ' max-sm:!w-full max-sm:bottom-0 flex items-center flex-col justify-center'}
        style={{
          width: '28%',
          display: step === 0 ? 'none' : 'flex',
        }}

      >
        <div
          className={'flex items-center justify-center w-full flex-col pb-6 bg-white rounded-t-[25px] '}
          style={{
            height: '100%',
          }
          }
        >
          <div
            className={'flex items-center justify-center bg-white w-full flex-col max-sm:pb-6'}>
            {activeRightComponent()}
          </div>
        </div>
        {/* {step === 0 && */}
        {/*   <InfoBlock */}
        {/*     showFriends={showFriends} */}
        {/*     setStep={setStep} */}
        {/*     activeStep={activeStep} */}
        {/*   /> */}
        {/* } */}
      </div>
      <CompareResultsModal show={showCompareModal} onSkip={handleCompareResultsModalSkip} />
      <ShareLinkModal show={showLinkModal} onSkip={handleShareLinkModalSkip} />
      <Modal show={showEmailModal}>
        <Formik
          initialValues={{
            email: email || '',
          }}
          onSubmit={async (values, { setFieldError }) => {
            if (values.email) {
              ReactGA.event({
                category: 'Add waitlist',
                action: 'Waitlist added',
                label: values.email,
              })
              postFunnel({
                waitingListEmail: values.email,
              }).then()
              await addWaitlist({
                email: values.email,
              })
                .then(() => {
                  setShowEmailModal(false)
                  setShowValidateModal(true)
                  setTooltip(true)
                })
                .catch((e) => {
                  setFieldError('email', e?.response?.data?.message)
                })
            }
          }}
        >
          {(formikProps) => {
            const { values, handleChange, handleSubmit, errors } = formikProps
            return (
              <form
                onSubmit={handleSubmit}
                className={'flex flex-col items-start justify-between'}
                style={{ maxWidth: '417px' }}
              >
                <div
                  className={'flex items-center justify-between w-full'}
                  style={{ marginBottom: '20px' }}
                >
                  <div className={'share-title'}>✉️</div>
                  <div
                    className={'share-skip'}
                    aria-label='close'
                    onClick={() => {
                      setShowEmailModal(false)
                    }}
                  >
                    Close
                  </div>
                </div>

                <div className={'share-title'}>Subscribe to Waitlist</div>
                <div className={'share-message'}>
                  Join our private beta and we will reach out. <br />
                  We’ll be ready soon.
                </div>
                <TextField
                  name='email'
                  variant='outlined'
                  color={'primary'}
                  InputProps={{
                    type: 'email',
                  }}
                  placeholder={'Your email address'}
                  className={'w-full'}
                  value={values.email}
                  style={{ marginBottom: '16px', marginTop: '46px' }}
                  onChange={handleChange}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '15px',
                      backgroundColor: '#FFFFFF',
                    },
                  }}
                />
                {errors.email && (
                  <div className={'text-red-500 text-[12px] mb-5 ml-2 text-center'}>
                    {errors.email}
                  </div>
                )}
                <PrimaryButton type='submit' disabled={!values.email}>
                  <Typography
                    style={{
                      color: '#fff',
                      fontSize: 24,
                      fontWeight: '700',
                      textTransform: 'none',
                    }}
                  >
                    Continue
                  </Typography>
                </PrimaryButton>
              </form>
            )
          }}
        </Formik>
      </Modal>
      <animated.div
        className={'absolute right-4 top-4 z-[10000] max-sm:left-4  flex items-center justify-center'}
        style={{
          ...springTooltip,
        }}
      >
        <WaitlistTooltip />
      </animated.div>
      <animated.div
        className={'absolute right-4 top-4 z-[10000] max-sm:left-4  flex items-center justify-center'}
        style={{
          ...springErrorTooltip,
        }}
      >
        <ErrorTooltip errorTooltip={errorTooltip} />
      </animated.div>
      {step === 0 && activeStep === null &&
        <div
          className={'fixed bottom-0 right-0 left-0 flex items-center justify-center  '}

        >
          <div className={'mt-4 rounded-t-[25px] py-8 px-4 bg-white shadow-2xl drop-shadow-2xl'}
               style={{
                 width: step === 0 ? '100%' : '72%',
                 maxWidth: step === 0 ? '512px' : '72%',
               }}
          >
            <CoverEmissionButton setStep={setStep} showFriends={showFriends} big={true} />
          </div>
        </div>
      }
    </div>
  )
}

export default memo(Results)
