import React from 'react'

interface LoadingModalProps {
  checkTransaction: () => void
  handleCancel: () => void
}
const LoadingModal: React.FC<LoadingModalProps> = ({
  checkTransaction,
  handleCancel,
}) => {
  return (
    <div
      className={'absolute top-0 left-0 w-full h-full bg-[#FFFFFF60] backdrop-blur-[59px] z-[1250] flex items-center justify-center'}>
      <div className={'w-[90%] sm:w-[50%] max-w-[452px] p-4 rounded-lg flex items-start justify-start'}>
        <div className={'text-[16px] text-start pb-2 text-[#000000]'}>
          We still waiting update from our partners CrossMint.
          <br />
          It can take up to 5 minutes.
          <br />
          <br />
          <span
            className={'text-[16px] text-start text-[#000000]'}>Have you been viewing this page for a while?<br />Please let us know wethever you
                     <span className={'font-bold text-[16px] underline hover:no-underline cursor-pointer px-1'}
                           onClick={checkTransaction}>completed</span>your purchase or <span onClick={handleCancel}
                                                                                             className={'font-bold text-[16px] cursor-pointer underline hover:no-underline'}>decided to cancel</span>
                  </span>
        </div>
      </div>
    </div>
  )
}

export default LoadingModal