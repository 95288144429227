import React, { type FC } from 'react'

interface IComparisonSwitcher {
  refState: boolean
  showFriends: number
  setShowFriends: (value: number) => void
}
export const ComparisonSwitcher: FC<IComparisonSwitcher> = ({
  refState,
  showFriends,
  setShowFriends,
}) => {
  if (refState)
    return (
      <div className={'flex flex-row items-center justify-center mt-4 w-full'}>
        <div
          className={
            'flex flex-row items-center justify-between rounded-[24px] p-1 w-full bg-[#EDEDED]'
          }
        >
          <div
            className={
              'rounded-[24px] cursor-pointer py-1 w-1/3 text-[14px] text-center truncate  ' +
              (showFriends === 0 ? ' bg-[#000] text-[#fff]' : ' text-[#000]')
            }
            onClick={() => setShowFriends(0)}
          >
            Mine
          </div>
          <div
            className={
              'rounded-[24px] cursor-pointer py-1 w-1/3 text-[14px] text-center truncate ' +
              (showFriends === 1 ? ' bg-[#000] text-[#fff]' : ' text-[#000]')
            }
            onClick={() => setShowFriends(1)}
          >
            Friend`s
          </div>
          <div
            className={
              'rounded-[24px] cursor-pointer py-1 w-1/3 text-[14px] text-center truncate  ' +
              (showFriends === 2 ? ' bg-[#000] text-[#fff]' : ' text-[#000]')
            }
            onClick={() => setShowFriends(2)}
          >
            Compare
          </div>
        </div>
      </div>
    )
  else return null
}
