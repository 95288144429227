import axios from 'axios'
import config from '../config'
import { getMachineId } from '../utils'

export const client = axios.create({
  baseURL: `https://${config.apiEndpoint}`,
  headers: {
    'Content-Type': 'application/json',
    'device-id': getMachineId(),
  },
})

export const getSurvey = async (ref: string | null) => {
  return await client.get(`/survey?ref=${ref}`).then((response) => response.data)
}

// eslint-disable-next-line
export const postSurvey = async (data: any) => {
  return await client.post('/survey', data).then((response) => response.data)
}

export const getSurveyRef = async (ref: string | null) => {
  return await client.get(`/survey/myref?ref=${ref}`).then((response) => response.data)
}

export const getSurveyTakeAnAction = async (ref?: string | null) => {
  return await client.get(`/survey/takeAnAction?ref=${ref}`).then((response) => response.data)
}

export const getSurveyFootprint = async (ref: string | null) => {
  return await client.get(`/survey/footprint?ref=${ref}`).then((response) => response.data)
}

export const getSurveyFeedback = async (ref?: string) => {
  return await client.get(`/survey/feedback?ref=${ref}`).then((response) => response.data)
}

// eslint-disable-next-line
export const postSurveyFeedback = async (data: any) => {
  return await client.post('/survey/feedback', data).then((response) => response.data)
}

// User
// eslint-disable-next-line
export const postUser = async (data: any) => {
  return await client.post('/user', data).then((response) => response.data)
}

// Join private beta
// eslint-disable-next-line
export const joinPrivateBeta = async (data: any) => {
  return await client.post('/subscribePrivateBeta', data).then((response) => response.data)
}

// Logs
// eslint-disable-next-line
export const postLog = async (data: any) => {
  return await client.post('/logs/survey', data).then((response) => response.data)
}
// Share-link
export const getShareLink = async () => {
  return await client.get('/shareLink').then((response) => response.data)
}
// Get session
export const getSession = async () => {
  return await client.get('/webhook/generate-session').then((response) => response.data)
}

// Check transaction status
export const checkTransactionStatus = async (id: string | null) => {
  const data = await client.get(`/webhook/checkTransaction/${id}`).then((response) => response.data)
  return data
}

// eslint-disable-next-line
export const addWaitlist = async (data: any) => {
  return await client.post('/waitlist', data).then((response) => response.data)
}
export const checkCode = (code: string) =>
  client.post('/codes/check', { code }).then((res) => res.data)

// eslint-disable-next-line
export const postFeedback = async (data: any) => {
  return await client.post('/feedback', data).then((response) => response.data)
}

// eslint-disable-next-line
export const postFunnel = async (data: any) => {
  return await client.post('/funnel', data).then((response) => response.data)
}

