import React, { useEffect } from 'react'
import LoaderPulse from '../../components/LoaderPulse'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { postFunnel } from '../../utils/api'
import { formatDate } from '../../utils/helpers'
import quizStore from '../../stores/answers'
import { useQuery } from 'react-query'

const RedirectPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const searchReference = searchParams.get('ref')
  const accessCode = searchParams.get('code')
  const navigate = useNavigate()
  const ref = searchReference || localStorage.getItem('ref') || ''
  localStorage.setItem('accessCode', accessCode || '')


  useEffect(() => {
    const {
      ...restParams
    } = Object.fromEntries(searchParams.entries())

    if (ref) {
      localStorage.setItem('ref', ref)
    }
    if (accessCode) {
      localStorage.setItem('accessCode', accessCode)
    }

    // Construct the new URL
    const newSearchParams = new URLSearchParams({
      ...restParams,
      ref,
    })
    ref ? navigate(`/survey?${newSearchParams.toString()}`) : navigate(`/survey?${newSearchParams.toString()}`)
  }, [])

  useEffect(() => {
    quizStore.clearUserAnswers()
  }, [])

  postFunnel({
    datetime: formatDate(new Date()),
    source: location.search,
    quizFinishDatetime: '',
  }).then()

  return (
    <div>
      <LoaderPulse />
    </div>
  )
}

export default RedirectPage
