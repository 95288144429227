import React, { memo, useState } from 'react'
import './styles.scss'
import { useNavigate } from 'react-router-dom'
import MiddleBg from '../../assets/svg/share_middle_bg.svg'
import Or from '../../assets/svg/or_separator.svg'
import ShareIllustration from '../../assets/svg/share-illustration.svg'
import { Button, Slider, TextField, Typography } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import Modal from '../../shared/Modal'
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'
import Colors from '../../constants/colors'
import Box from '@mui/material/Box'
import PrimaryButton from '../../components/PrimaryButton'
import SecondaryButton from '../../components/SecondaryButton'
import { ISurveyFeedback } from '../../shared/Inrefaces'
import { postSurveyFeedback, postUser } from '../../utils/api'

const Share = () => {
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showCompareModal, setShowCompareModal] = useState(false)
  const [showFeedBackModal, setShowFeedBackModal] = useState(false)
  const [link, setLink] = useState('')
  const [email, setEmail] = useState('')
  const [feedback, setFeedback] = useState('')
  const [qQuestionsClear, setQQuestionsClear] = useState({
    label: null,
    value: 3,
    description: 'Yep',
  })
  const [qResultsClear, setQResultsClear] = useState({
    label: null,
    value: 3,
    description: 'Yep',
  })
  const title = 'Check out my Pyrpose results!'

  const marks = [
    {
      label: null,
      value: 0,
      description: 'Nope',
    },
    {
      label: null,
      value: 1,
      description: 'Not really',
    },
    {
      label: null,
      value: 2,
      description: 'Mostly yes',
    },
    {
      label: null,
      value: 3,
      description: 'Yep',
    },
  ]
  const getShareLink = async () => {
    const response = await getShareLink()
    console.log(response, 'response')
    const link = response
    setLink(`${window.location.protocol}//${window.location.hostname}${link}`)
    setShowCompareModal(!showCompareModal)
  }

  const copyShareLink = async () => {
    navigator.clipboard.writeText(link)
    setOpen(true)
    setTimeout(() => {
      setOpen(false)
    }, 3000)
  }

  const saveEmail = async () => {
    postUser({ email }).then()
    setShowCompareModal(false)
    setShowModal(true)
  }

  const handleSkip = () => {
    navigate('/take-an-action')
  }

  const handleShareToggleModal = () => {
    setShowCompareModal(false)
    setShowModal(true)
  }
  const handleFeedbackModal = () => {
    setShowModal(false)
    setShowFeedBackModal(true)
  }

  const handleTakeAction = () => {
    setShowFeedBackModal(false)
    handleSkip()
  }

  const handleSendFeedback = async () => {
    const feedbackReq: ISurveyFeedback = {
      qQuestionsClear: { value: qQuestionsClear.value },
      qResultsClear: { value: qResultsClear.value },
      feedback: feedback,
    }
    postSurveyFeedback(feedbackReq).then()
    navigate('/take-an-action')
  }

  return (
    <div
      className={'w-full h-full flex flex-col min-h-screen items-center'}
      style={{
        backgroundColor: '#B1F4F0',
      }}
    >
      <br />
      <div className={'flex flex-row justify-end sm:w-2/3 md:w-1/3 w-full px-4 pt-10'}>
        <button className='text-md' onClick={handleSkip}>
          <div className={'share-skip'} aria-label='close'>
            skip &#x3e;
          </div>
        </button>
      </div>

      <div
        style={{ height: 'auto', position: 'absolute', top: '10%' }}
        className='flex flex-col items-center justify-evenly sm:w-2/3 md:w-1/3 w-full px-4'
      >
        <Typography variant='body1' className={'!font-bold !text-[32px] text-center mt-4 '}>
          Share your results with friends
        </Typography>
      </div>
      <div
        className='w-full absolute left-0 right-0 bottom-[45%]'
        style={{ backgroundImage: `url(${MiddleBg})`, height: '180px', marginBottom: '-1px' }}
      ></div>
      <div
        className='w-full absolute left-0 right-0 top-[55%] bottom-0'
        style={{ backgroundColor: '#90BF98' }}
      ></div>

      <img
        src={ShareIllustration}
        alt={'ShareIllustration'}
        style={{ height: 'auto', position: 'absolute', bottom: '45%' }}
      />

      <div
        style={{ height: 'auto', position: 'absolute', bottom: '30%' }}
        className='flex flex-col items-center justify-evenly sm:w-2/3 md:w-1/3 w-full px-4'
      >
        <Typography variant='body1' className={'!font-bold !text-[18px] text-center mt-4 '}>
          Tell your friends about your result, they can also take this survey and you can compare
          results!
        </Typography>
      </div>
      <div
        style={{ height: 'auto', position: 'absolute', bottom: '5%' }}
        className='flex flex-col items-center justify-evenly sm:w-2/3 md:w-1/3 w-full px-4'
      >
        <Button
          variant='contained'
          onClick={getShareLink}
          className={'w-full px-4 mt-6 h-[61px]'}
          sx={{
            borderRadius: '15px',
            backgroundColor: '#000000',
            color: '#FFFFFF',
            fontWidth: 'bold',
            fontSize: 24,
            marginTop: '24px',
          }}
        >
          Get Share Link
        </Button>
      </div>

      <Modal show={showCompareModal}>
        <div className={'flex flex-col max-w-[416px] items-start justify-between'}>
          <div className={'flex items-center justify-between w-full mb-[24px]'}>
            <div className={'share-title'}>🤲</div>
            <div className={'share-skip'} aria-label='close' onClick={handleShareToggleModal}>
              skip
            </div>
          </div>

          <div className={'share-title'}>Compare results with friends</div>
          <div className={'share-message mb-[46px]'}>
            To enable us to notify you of your friend&apos;s results, please leave your email
            address
          </div>
          <TextField
            variant='outlined'
            color={'primary'}
            placeholder={'Your email address'}
            className={'w-full px-4 '}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '15px',
                backgroundColor: '#FFFFFF',
              },
            }}
          />
          <PrimaryButton
            type={'button'}
            onClick={saveEmail}
            disabled={!email}
            className={'!mt-[17.5px]'}
          >
            <Typography
              style={{ color: '#fff', fontSize: 24, fontWeight: '700', textTransform: 'none' }}
            >
              Done
            </Typography>
          </PrimaryButton>
        </div>
      </Modal>

      <Modal show={showModal}>
        <div
          className={'flex flex-col md:min-w-[416px] max-w-[416px]  items-start justify-between'}
        >
          <div className={'flex items-center justify-between w-full'}>
            <div className={'share-title'}>📬 Share</div>
            <div className={'share-skip'} aria-label='close' onClick={handleFeedbackModal}>
              skip
            </div>
          </div>

          <div className={'share-message mb-[56px]'}>
            Share your results using services or just copy the link
          </div>

          <div className={'flex items-center justify-evenly w-full'}>
            <WhatsappShareButton url={`whatsapp://send?${link}`} title={title}>
              <WhatsappIcon size={48} round />
            </WhatsappShareButton>
            <FacebookShareButton url={link} quote={title}>
              <FacebookIcon size={48} round />
            </FacebookShareButton>
            <TwitterShareButton url={link}>
              <TwitterIcon size={48} round />±
            </TwitterShareButton>
            <TelegramShareButton url={link} title={title}>
              <TelegramIcon size={48} round />
            </TelegramShareButton>
            <LinkedinShareButton url={link} title={title}>
              <LinkedinIcon size={48} round />
            </LinkedinShareButton>
          </div>

          <div
            className='w-full'
            style={{
              backgroundImage: `url(${Or})`,
              height: '22px',
              backgroundSize: 'contain',
              marginTop: '22px',
              marginBottom: '23px',
            }}
          ></div>

          <TextField
            variant='outlined'
            color={'primary'}
            placeholder={'Link'}
            className={'w-full px-4 '}
            value={`🖇️ ${link}`}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '15px',
                backgroundColor: '#FFFFFF',
                marginBottom: '17px',
              },
            }}
          />
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title='Link copied to clipboard!'
          >
            <SecondaryButton
              variant='outlined'
              onClick={copyShareLink}
              className={'w-full px-4 h-[61px]'}
            >
              <Typography
                style={{ color: '#000000', fontSize: 22, fontWeight: '700', textTransform: 'none' }}
              >
                Copy Link
              </Typography>
            </SecondaryButton>
          </Tooltip>
        </div>
      </Modal>

      <Modal show={showFeedBackModal}>
        {/* <div className={'flex flex-col items-start justify-between modal-container'}>*/}
        <div className={'modal-container flex items-center justify-center flex-col'}>
          <div className={'w-full modal-header mb-[24px]'}>
            <div className={'share-title'}>🔮</div>
            <div className={'share-skip'} aria-label='close' onClick={handleTakeAction}>
              skip
            </div>
          </div>
          <div className={'modal-content w-full'}>
            <div className={'share-title'} style={{ marginBottom: '48px' }}>
              How was your experience?
            </div>
            <div className={'w-full  feedback-question mb-[12px]'}>
              Did you understand the questions in the quiz?
            </div>
            <Box className='w-full flex flex-row justify-between items-center'>
              <Typography className='!sm:text-[20px] !text-[20px] !font-bold'>
                {marks[0].description}
              </Typography>
              <Typography className='!sm:text-[20px] !text-[20px] !font-bold'>
                {marks[3].description}
              </Typography>
            </Box>
            <div className={'w-full flex flex-col items-center'}>
              <div className={'w-[85%]'}>
                <Slider
                  style={styles.slider}
                  aria-label='ios slider'
                  step={1}
                  min={0}
                  max={3}
                  marks={marks}
                  value={qQuestionsClear.value}
                  onChange={(e, val) => {
                    const selected = marks.find((mark) => mark.value === val)
                    if (selected) {
                      setQQuestionsClear(selected)
                    }
                  }}
                  sx={{
                    '& .MuiSlider-thumb': {
                      height: 40,
                      width: 40,
                      marginRight: '-25px',
                      marginLeft:
                        qQuestionsClear.value === 0
                          ? '-6px'
                          : window.innerWidth > 768
                          ? '0.7%'
                          : '0px',
                      borderRadius: '25px',
                      backgroundColor: '#78FD85',
                      boxShadow: '0px 0px 0px 0px rgba(120,253,133,0.3)',
                      border: '0px solid #78FD85',
                      borderTopRightRadius: '25px',
                      borderBottomRightRadius: '25px',
                      overflow: 'hidden',
                      '&:focus, &:hover, &$active': {
                        boxShadow: '0px 0px 0px 0px #000 !important',
                      },
                    },
                    '& .MuiSlider-thumb:hover': {
                      boxShadow: '0px 0px 0px 0px #000 !important',
                    },
                    '& .MuiSlider-thumb:after': {
                      content: '""',
                      position: 'absolute',
                      width: 40,
                      height: 40,
                      borderRadius: '50%',
                      borderWidth: '4px',
                      borderStyle: 'solid',
                      borderColor: '#78FD85',
                      backgroundColor: '#fff',
                    },
                    '& .MuiSlider-thumb:before': {
                      boxShadow: '0px 0px 0px 0px #000 !important',
                    },
                    '& .MuiSlider-track': {
                      height: 40,
                      borderTopLeftRadius: 25,
                      borderBottomLeftRadius: 25,
                      border: 'none',
                      left: '-5.6% !important',
                      paddingRight: 20 + '!important',
                      width: `${(qQuestionsClear.value / 3) * 100 + 6}%  !important`,
                      backgroundColor: '#78FD85',
                    },
                    '& .MuiSlider-rail': {
                      border: 'none',
                      backgroundColor: '#000',
                      borderRadius: 25,
                      left: '-5%',
                      width: '108%',
                      opacity: 1,
                    },
                    '& .MuiSlider-mark': {
                      backgroundColor: '#fff',
                      height: 8,
                      width: 8,
                      borderRadius: '50%',
                      '&.MuiSlider-markActive': {
                        opacity: 1,
                        backgroundColor: '#78FD85',
                      },
                    },
                  }}
                />
                {qQuestionsClear ? (
                  <Box style={styles.rootValue}>
                    <Typography
                      className={'!sm:text-[20px] !text-[20px]'}
                      style={styles.titleValue}
                    >
                      {qQuestionsClear.description}
                    </Typography>
                  </Box>
                ) : null}
              </div>
            </div>

            <div className={'w-full  feedback-question mb-[12px]'}>
              Were the results of the quiz clear to you?
            </div>
            <Box className='w-full flex flex-row justify-between items-center'>
              <Typography className='!sm:text-[20px] !text-[20px] !font-bold'>
                {marks[0].description}
              </Typography>
              <Typography className='!sm:text-[20px] !text-[20px] !font-bold'>
                {marks[3].description}
              </Typography>
            </Box>
            <div className={'w-full flex flex-col items-center '}>
              <div className={'w-[85%]'}>
                <Slider
                  style={styles.slider}
                  aria-label='ios slider'
                  step={1}
                  min={0}
                  max={3}
                  marks={marks}
                  value={qResultsClear.value}
                  onChange={(e, val) => {
                    const selected = marks.find((mark) => mark.value === val)
                    if (selected) {
                      setQResultsClear(selected)
                    }
                  }}
                  sx={{
                    '& .MuiSlider-thumb': {
                      height: 40,
                      width: 40,
                      marginRight: '-25px',
                      marginLeft:
                        qResultsClear.value === 0
                          ? '-6px'
                          : window.innerWidth > 768
                          ? '0.7%'
                          : '0px',
                      borderRadius: '25px',
                      backgroundColor: '#78FD85',
                      boxShadow: '0px 0px 0px 0px rgba(120,253,133,0.3)',
                      border: '0px solid #78FD85',
                      borderTopRightRadius: '25px',
                      borderBottomRightRadius: '25px',
                      overflow: 'hidden',
                      '&:focus, &:hover, &$active': {
                        boxShadow: '0px 0px 0px 0px #000 !important',
                      },
                    },
                    '& .MuiSlider-thumb:hover': {
                      boxShadow: '0px 0px 0px 0px #000 !important',
                    },
                    '& .MuiSlider-thumb:after': {
                      content: '""',
                      position: 'absolute',
                      width: 40,
                      height: 40,
                      borderRadius: '50%',
                      borderWidth: '4px',
                      borderStyle: 'solid',
                      borderColor: '#78FD85',
                      backgroundColor: '#fff',
                    },
                    '& .MuiSlider-thumb:before': {
                      boxShadow: '0px 0px 0px 0px #000 !important',
                    },
                    '& .MuiSlider-track': {
                      height: 40,
                      borderTopLeftRadius: 25,
                      borderBottomLeftRadius: 25,
                      border: 'none',
                      left: '-5.6% !important',
                      paddingRight: 20 + '!important',
                      width: `${(qResultsClear.value / 3) * 100 + 6}%  !important`,
                      backgroundColor: '#78FD85',
                    },
                    '& .MuiSlider-rail': {
                      border: 'none',
                      backgroundColor: '#000',
                      borderRadius: 25,
                      left: '-5%',
                      width: '108%',
                      opacity: 1,
                    },
                    '& .MuiSlider-mark': {
                      backgroundColor: '#fff',
                      height: 8,
                      width: 8,
                      borderRadius: '50%',
                      '&.MuiSlider-markActive': {
                        opacity: 1,
                        backgroundColor: '#78FD85',
                      },
                    },
                  }}
                />
                {qResultsClear ? (
                  <Box style={styles.rootValue}>
                    <Typography
                      className={'!sm:text-[20px] !text-[20px]'}
                      style={styles.titleValue}
                    >
                      {qResultsClear.description}
                    </Typography>
                  </Box>
                ) : null}
              </div>
            </div>

            <div className={'w-full  feedback-label mb-[16px]'}>Share with us your feedback</div>
            <TextField
              variant='outlined'
              color={'primary'}
              placeholder={'Your thoughts'}
              className={'w-full px-4'}
              onChange={(e) => setFeedback(e.target.value)}
              value={feedback}
              multiline
              rows={4}
              sx={{
                marginBottom: '16px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '15px',
                  backgroundColor: '#FFFFFF',
                },
              }}
            />
            <div className={'w-full max-sm:mb-4'}>
              <PrimaryButton
                variant='contained'
                onClick={handleSendFeedback}
                className={'w-full px-4 my-6 h-[61px]'}
              >
                <Typography
                  style={{ color: '#fff', fontSize: 24, fontWeight: '700', textTransform: 'none' }}
                >
                  Share Feedback
                </Typography>
              </PrimaryButton>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const styles = {
  slider: {
    // paddingHorizontal: 20,
    borderRadius: 4,
    height: 40,
  },
  labels: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 10,
  },
  label: {
    fontSize: 18,
    color: Colors.basic.black,
  },
  description: {
    fontSize: 16,
    color: Colors.basic.black,
    marginTop: 8,
  },
  rootValue: {
    marginTop: 8,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  mainTitle: {
    fontSize: 26,
    textAlign: 'center',
    fontWeight: 'bold',
    color: Colors.basic.black,
  },
  subTitle: {
    fontSize: 18,
    textAlign: 'center',
    color: Colors.basic.black,
    marginTop: 12,
  },
  thumb: {
    width: 50,
    height: 50,
    borderRadius: 30,
    backgroundColor: '#A9A9A9',
  },
  track: {
    height: 15,
    borderRadius: 10,
  },
  titleValue: {
    color: Colors.basic.black,
    fontWeight: 'bold',
  },
  descriptionValue: {
    fontSize: 12,
    height: 20,
    color: Colors.basic.black + '70',
    marginTop: 8,
  },
  shareSkip: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '24px',
  },
}

export default memo(Share)
