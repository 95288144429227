import React from 'react'
import NOAA from '../../../assets/img/noaa.png'
import LSE from '../../../assets/img/lse.png'

interface DataBlockProps {
  handleClick: () => void
}

const DataBlock: React.FC<DataBlockProps> = ({
  handleClick,
}) => {
  return (
    <div
      className='w-full px-4 py-20 bg-gradient-to-b from-zinc-100 to-zinc-100 flex-col justify-start items-center gap-10 inline-flex'>
      <div className='w-full text-center text-stone-900 text-[32px] font-bold leading-[35px]'>The data that proves we
        must act now
      </div>
      <div className='self-stretch flex-col justify-start items-center gap-4 flex'>
        <div
          className='self-stretch  p-8 bg-emerald-100 rounded-2xl shadow flex-col justify-start items-start gap-12 flex'
        style={{
          boxShadow: '0px 12px 20px rgba(0, 0, 0, 0.05)'
        }}
        >
          <div className='self-stretch  flex-col justify-center items-start gap-2 flex'>
            <img src={NOAA} alt='NOAA' />
            <div className='self-stretch  flex-col justify-start items-start flex'>
              <div className='self-stretch  flex-col justify-start items-start gap-4 flex'>
                <div className='text-start self-stretch'><span
                  className='text-zinc-900 text-start text-2xl font-bold leading-[28.80px]'>😧 <br /></span><span
                  className='text-zinc-900 text-2xl font-bold leading-[28.80px]'>Carbon Dioxide 50% higher than pre-industrial</span>
                </div>
                <div className='w-[54px] h-[0px] border border-black'></div>
                <div className='self-stretch text-black text-start text-base font-normal leading-snug'>In a recent press
                  release, NOAA reported from the Mauna Loa Observatory that carbon dioxide reached 421 PPM
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className='self-stretch  p-8 bg-sky-100 rounded-2xl shadow flex-col justify-start items-start gap-12 flex'>
          <div className='self-stretch  flex-col justify-center items-start gap-2 flex'>
            <img src={LSE} alt='LSE' />
            <div className='self-stretch  flex-col justify-start items-start flex'>
              <div className='self-stretch  flex-col justify-start items-start gap-4 flex'>
                <div className='self-stretch text-start text-zinc-900 text-2xl font-bold leading-[28.80px]'>🌲<br />Deforestation
                  causing up to 20% of greenhouse gas emissions.
                </div>
                <div className='w-[54px] h-[0px] border border-black'></div>
                <div className='self-stretch text-black text-start text-base font-normal leading-snug'>A recent study by
                  LSE showed that humans are the cause of deforestation and estimates around 420 million hectares lost
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={handleClick}
        className='self-stretch h-16 px-6 py-4 bg-zinc-900 rounded-2xl justify-center items-center gap-2 inline-flex'>
        <div className='text-center text-green-300 text-2xl font-bold'>Get your personal results</div>
      </div>
    </div>
  )
}

export default DataBlock
