import React from 'react'
import IntroductionBackground from '../../../assets/svg/introduction-background.svg'
interface IntroductionBlockProps {
  handleClick: () => void

}

const IntroductionBlock: React.FC<IntroductionBlockProps> = ({
  handleClick,
}) => {
  return (
    <div
      className='w-full px-4 py-[72px] bg-gradient-to-b from-zinc-100 to-zinc-100 flex-col justify-start items-start gap-8 inline-flex'
      style={{
        backgroundImage: `url(${IntroductionBackground})`,
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPositionY: 'center',
      }}
    >
      <div className='justify-start items-start gap-2 inline-flex'>
        <div className=' text-center text-black text-[32px] font-bold leading-[35px]'>The annual average
          pollution per person is equal to:
        </div>
      </div>
      <div className=' flex justify-between w-full items-center flex-row'>
        <div className=' text-center text-black text-[64px] w-[30%] font-bold'>🧊</div>
        <div className='text-center text-black text-[64px] w-[30%] font-bold'>🌲</div>
        <div className=' text-center text-black text-[64px] w-[30%] font-bold'>🐋</div>
      </div>
      <div className=' flex justify-between w-full items-start flex-row'>
        <div className=' flex flex-col justify-start items-center gap-2.5 w-[30%]'>
          <div className='self-stretch text-center text-black text-2xl font-extrabold'>15</div>
          <div className=' text-center text-black text-xs font-normal leading-[18px]'>square meters of sea
            ice loss
          </div>
        </div>
        <div className='grow shrink basis-0 opacity-50  text-gray-400 text-2xl font-extrabold'>or</div>
        <div className=' flex flex-col justify-start items-center gap-2.5 w-[30%]'>

          <div className='self-stretch text-center text-black text-2xl font-extrabold'>359</div>
          <div className='self-stretch text-center text-black text-xs font-normal leading-[18px]'>cut down trees</div>
        </div>
        <div className='grow shrink basis-0 opacity-50  text-gray-400 text-2xl font-extrabold'>or</div>
        <div className=' flex flex-col justify-start items-center gap-2.5 w-[30%]'>
          <div className='self-stretch text-center text-black text-2xl font-extrabold'>19%</div>
          <div className='self-stretch text-center text-black text-xs font-normal leading-[18px]'>percent of the
            humpback whale weight
          </div>
        </div>
      </div>
      <div className='self-stretch h-[52px] flex-col justify-start items-center gap-10 flex'>
        <div className='self-stretch text-center text-black text-xl font-bold leading-relaxed'>You’ll be amazed by what
          you discover!
        </div>
      </div>
      <div className='w-full h-16 px-6 py-4 bg-zinc-900 rounded-2xl justify-center items-center gap-2 inline-flex cursor-pointer' onClick={handleClick}>
        <div className='text-center text-green-300 text-2xl font-bold'>2 minute discovery</div>
      </div>
    </div>
  )
}

export default IntroductionBlock
