import React from 'react'
import { TextField, Typography } from '@mui/material'
import IntroImage from '../../assets/svg/intro-bg.svg'
import { Formik } from 'formik'
import LogoGreen from '../../assets/svg/logo-green.svg'
import PrimaryButton from '../../components/PrimaryButton'
import * as Yup from 'yup'
import ReactGA from 'react-ga4'
import { addWaitlist } from '../../utils/api'
import { animated, useSpring } from '@react-spring/web'

export const JoinWaitList: React.FC = () => {
  const [springTooltip, setSpringTooltip] = useSpring(() => ({
    opacity: 0,
  }))
  const [showTooltip, setShowTooltip] = React.useState(false)
  React.useEffect(() => {
    if (showTooltip) {
      setSpringTooltip({
        opacity: 1,
      })
      setTimeout(() => {
        setShowTooltip(false)
        window.location.href = 'https://www.pyrpose.io'
      }, 3000)
    }
  }, [showTooltip])
  return (
    <div
      className={'w-full flex flex-1 flex-col items-center justify-center bg-white'}
      style={{ backgroundImage: `url(${IntroImage})`, backgroundSize: 'cover' }}
    >
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Invalid email address').required('Required'),
        })}
        onSubmit={async (values, { setFieldError }) => {
          if (values.email) {
            ReactGA.event({
              category: 'Add waitlist',
              action: 'Waitlist added',
              label: values.email,
            })
            await addWaitlist({
              email: values.email,
            })
              .then(() => {
                setShowTooltip(true)
              })
              .catch((error) => {
                setFieldError('email', error?.response?.data?.message)
              })
          }
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting, errors, touched }) => (
          <form
            onSubmit={handleSubmit}
            className={'sm:w-1/3 w-full flex flex-col items-center justify-center px-4'}
          >
            <div className={' w-full flex flex-col items-start justify-start'}>
              <img src={LogoGreen} alt='logo' className={'ml-[-20px]'} />
              <Typography
                sx={{
                  fontSize: '34px',
                  fontWeight: 700,
                  lineHeight: '32px',
                  textAlign: 'start',
                  pb: '10px',
                  color: '#000',
                }}
              >
                Join the waitlist
              </Typography>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  textAlign: 'start',
                  color: '#000',
                  mt: '10px',
                }}
              >
                We are currently in beta. Sign up to be notified when we launch.
              </Typography>
            </div>
            <div className={'w-full flex flex-col items-center justify-center'}>
              <TextField
                sx={{
                  width: '100%',
                  mt: '20px',
                }}
                id='email'
                label='Email'
                placeholder={'Enter your email'}
                variant='outlined'
                value={values.email}
                onChange={handleChange}
                error={!!errors.email && touched.email}
                helperText={errors.email && touched.email ? errors.email : ''}
              />
              <PrimaryButton
                sx={{
                  mt: '20px',
                }}
                variant='contained'
                type='submit'
                disabled={isSubmitting}
              >
                <Typography
                  style={{
                    color: '#fff',
                    fontSize: 24,
                    fontWeight: '700',
                    textTransform: 'none',
                  }}
                >
                  Submit
                </Typography>
              </PrimaryButton>
              <PrimaryButton
                sx={{
                  mt: '20px',
                  border: '1px solid #000',
                }}
                background={'#fff'}
                variant='contained'
                onClick={() => {
                  window.location.href = 'https://www.pyrpose.io'
                }}
              >
                <Typography
                  style={{
                    color: '#000',
                    fontSize: 24,
                    fontWeight: '700',
                    textTransform: 'none',
                  }}
                >
                  Go Back
                </Typography>
              </PrimaryButton>
            </div>
          </form>
        )}
      </Formik>
      <animated.div
        className={'absolute right-4 top-4 z-[100] max-sm:left-4  flex items-center justify-center'}
        style={{
          ...springTooltip,
        }}
      >
        <div
          className={
            'flex animate__fadeIn flex-row items-center justify-start sm:justify-between w-full px-2 py-4 bg-white rounded-[16px] shadow-md'
          }
          style={{
            border: '1px solid #78FD85',
          }}
        >
          <div className={'text-[20px] px-2 h-full'}>✅</div>
          <div className='flex flex-col px-2'>
            <div className={'text-[16px] font-bold text-start'}>You joined Waitlist</div>
            <div className={'text-[12px] text-start'}>
              We will send you an email when beta is ready
            </div>
          </div>
        </div>
      </animated.div>
    </div>
  )
}
