import React from 'react'
import Modal from '../../../shared/Modal'
import { TextField, Typography } from '@mui/material'
import PrimaryButton from '../../../components/PrimaryButton'

interface IModalProps {
  show: boolean
  onCancel: () => void
  // eslint-disable-next-line
  formik: any
  // eslint-disable-next-line
  question: any
}

const InputModal: React.FC<IModalProps> = ({ show, onCancel, formik, question }) => {
  const [field, setField] = React.useState<string>(formik.values[question.id].value || '')
  const text = question.values.find((v: any) => v?.type === 'input')
  return (
    <Modal show={show}>
      <form className={'flex flex-col items-start justify-between'} style={{ maxWidth: '417px' }} onSubmit={(e) => {
        e.preventDefault()
      }}>
        <div
          className={'flex items-center justify-between w-full'}
          style={{ marginBottom: '20px' }}
        >
          <div className={'share-title'}>✍️</div>
          <div
            className={'share-skip'}
            aria-label='close'
            onClick={() => {
              setField('')
              onCancel()
            }}
          >
            cancel
          </div>
        </div>

        <div className={'share-title'}>{text.title}</div>
        <div className={'share-message'}>
          {text.subtitle}
        </div>
        <TextField
          variant='outlined'
          color={'primary'}
          label={text?.placeholder}
          className={'w-full'}
          value={field}
          style={{ marginBottom: '16px', marginTop: '46px' }}
          onChange={(e) => {
            const regex = /^[0-9\b]+$/
            if (e.target.value === '' || regex.test(e.target.value)) setField(e.target.value)
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '15px',
              backgroundColor: '#FFFFFF',
            },
          }}
        />
        <PrimaryButton
          onClick={() => {
            formik.setFieldValue(question.id, {
              ...formik.values[question.id],
              value: parseFloat(field),
              type: 'input',
            })
            setField('')
            onCancel()
          }}
          type={'submit'}
          disabled={!field}
        >
          <Typography
            style={{ color: '#fff', fontSize: 24, fontWeight: '700', textTransform: 'none' }}
          >
            Save
          </Typography>
        </PrimaryButton>
      </form>
    </Modal>
  )
}

export default InputModal
