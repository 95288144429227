import React from 'react'
import { Button, Checkbox, CircularProgress, TextField, Typography } from '@mui/material'
import ReactGA from 'react-ga4'

export const PrivateBeta: React.FC = () => {
  const [checked, setChecked] = React.useState(false)
  const [status, setStatus] = React.useState('')

  return (
    <div
      className={
        'w-full min-h-screen h-full flex items-center justify-center bg-no-repeat bg-white bg-cover '
      }
      style={{
        backgroundImage: 'url(../../assets/svg/intro-bg.svg)',
      }}
    >
      <div className='container flex justify-center relative h-full   px-4'>
        <div className='flex flex-col items-center justify-evenly sm:w-2/3 md:w-1/3 w-full h-full'>
          {status === 'success' && (
            <div className={'flex flex-col items-center justify-center w-full h-full'}>
              <div className={'flex flex-col mb-12'}>
                <Typography variant='body1' className={'!font-bold !text-[32px] text-center mt-4 '}>
                  🥑 pyrpose beta
                </Typography>
              </div>
              <div
                style={{ color: 'green' }}
                dangerouslySetInnerHTML={{ __html: '✅You are in the list' }}
              />
            </div>
          )}
          {status === '' && (
            <form
              onSubmit={async (e) => {
                e.preventDefault()
                const formData = {
                  EMAIL: e.currentTarget.email.value,
                  FNAME: e.currentTarget.fname.value,
                  LNAME: e.currentTarget.surname.value,
                }
                ReactGA.event({
                  category: 'joinBeta',
                  action: 'Join Private Beta',
                  label: e.currentTarget.email.value,
                })
                const response = await fetch(
                  'https://webhook.site/20596abf-eafb-4f02-90ec-98bd889af79b',
                  {
                    method: 'POST',
                    body: JSON.stringify(formData),
                  },
                )
                if (response.status === 200) {
                  setStatus('success')
                } else {
                  setStatus('error')
                }
              }}
              className={'flex flex-col items-center justify-center w-full h-full'}
            >
              <div className={'flex flex-col mb-12'}>
                <Typography variant='body1' className={'!font-bold !text-[32px] text-center mt-4 '}>
                  🥑 pyrpose beta
                </Typography>
                <Typography variant='body1' className={'!text-[16px]  text-center px-6 '}>
                  Pyrpose is curating the solutions that reduce climate emissions and plus you get
                  rewarded for supporting.
                </Typography>
                <Typography variant='body1' className={'!text-[16px]  text-center px-6 '}>
                  Join our private beta and we will reach out. We’ll be ready soon.
                </Typography>
              </div>
              <TextField
                id='email'
                label='Email'
                variant='outlined'
                type='email'
                color={'primary'}
                placeholder={'Your email'}
                className={'w-full px-4 '}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '15px',
                    backgroundColor: '#FFFFFF',
                  },
                }}
              />
              <div className={'h-4'} />
              <TextField
                id='fname'
                label='Name'
                type='text'
                variant='outlined'
                color={'primary'}
                placeholder={'Your name'}
                className={'w-full px-4'}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '15px',
                    backgroundColor: '#FFFFFF',
                  },
                }}
              />
              <div className={'h-4'} />
              <TextField
                id='surname'
                label='Surname'
                type='text'
                variant='outlined'
                color={'primary'}
                placeholder={'Your surname'}
                className={'w-full px-4 '}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '15px',
                    backgroundColor: '#FFFFFF',
                  },
                }}
              />
              <div className={'flex flex-row items-center pt-8 w-full'}>
                <Checkbox
                  onChange={(e) => setChecked(e.target.checked)}
                  checked={checked}
                  color='primary'
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  className={' mt-6 -pr-2'}
                />
                <Typography variant='body1' className={'!text-[12px]  text-left px-6 text-[] '}>
                  I agree to the{' '}
                  <a
                    href={'https://pyrpose.io/terms-of-use'}
                    target={'_blank'}
                    className={' underline '}
                    rel='noreferrer'
                  >
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a
                    href={'https://pyrpose.io/privacy-policy'}
                    target={'_blank'}
                    className={'underline'}
                    rel='noreferrer'
                  >
                    Privacy Policy
                  </a>
                </Typography>
              </div>
              <Button
                variant='contained'
                type='submit'
                disabled={!checked}
                className={'w-full px-4 mt-6 h-[61px]'}
                sx={{
                  borderRadius: '15px',
                  backgroundColor: '#000000',
                  color: '#FFFFFF',
                  fontWidth: 'bold',
                  fontSize: 24,
                  marginTop: '24px',
                }}
              >
                JOIN
              </Button>
            </form>
          )}
        </div>
      </div>
      {status === 'sending' && (
        <div className='flex justify-center items-center absolute top-0 left-0 right-0 bottom-0 z-50 bg-white bg-opacity-50 backdrop-filter backdrop-blur-sm'>
          <CircularProgress color='success' />
        </div>
      )}
    </div>
  )
}
