import React, { useEffect, useRef, useState } from 'react'
import './App.css'
import { Route, Routes, useLocation } from 'react-router-dom'
import Intro from './pages/Intro'
import { Subscribe } from './pages/Subscribe'
import { PrivateBeta } from './pages/beta'
import { GDPR } from './pages/gdpr'
import Share from './pages/Share'
import HowItWorks from './pages/HowItWorks'
import HowToReduce from './pages/HowToReduce'
import { JoinWaitList } from './pages/WaitList'
import TakeAnAction from './pages/TakAnAction'
import config from './config'
import ReactGA from 'react-ga4'
import quizStore from './stores/answers'
import Survey from './pages/Quiz/Survey'
import { useQuery } from 'react-query'
import { getSurvey, postFunnel } from './utils/api'
import Results from './pages/Results'
import LoaderPulse from './components/LoaderPulse'
import SuccessForm from './pages/Results/SuccessForm'
import ClearCache from './pages/ClearCache'
import { Helmet } from 'react-helmet-async'
import RedirectPage from './pages/RedirectPage'
import ReactPixel from 'react-facebook-pixel'
import Introduction from './pages/Introduction'
import LandingPage from './pages/Landing'
import { createLandingData, findAbTest, formatDate } from './utils/helpers'

const MetaPixel = ({ fbPixelId }: { fbPixelId: string }) => {
  const fbPixelScript = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', ${fbPixelId});
    fbq('track', 'PageView');
  `
  return (
    <Helmet>
      <script id='facebook-pixel-script'>
        {
          fbPixelScript
        }</script>
      <noscript id='facebook-pixel-image'>
        {`
        <img
        height='1'
        width='1'
        className='hidden'
        src='https://www.facebook.com/tr?id=${fbPixelId}&ev=PageView&noscript=1'
      />
        `}
      </noscript>
    </Helmet>
  )
}

/*
const MouseFlow = () => {
  const mouseFlowId = 'fb5270f3-061e-431e-b51a-aa8107995d64'
  const mouseFlowScript = `
    window._mfq = window._mfq || [];
    (function() {
      var mf = document.createElement("script");
      mf.type = "text/javascript"; mf.defer = true;
      mf.src = "//cdn.mouseflow.com/projects/${mouseFlowId}.js";
      document.getElementsByTagName("head")[0].appendChild(mf);
    })();
  `;
  return (
      <Helmet>
        <script type="text/javascript">
          {mouseFlowScript}
        </script>
      </Helmet>
  )
}
*/

function App() {
  const GOOGLE_ANALYTICS_ID = config?.reactGAKey || ' '
  const searchParams = new URLSearchParams(window.location.search)
  const referenceCode = searchParams.get('ref')
  const height = window.visualViewport?.height || window.innerHeight
  const location = useLocation()
  const variantAbTest = localStorage.getItem('abtest') || ''
  const [ABTest, setABTest] = useState(variantAbTest || (Math.floor(Math.random() * 5) + 1).toString())
  if (!variantAbTest) localStorage.setItem('abtest', ABTest)

  const hasGAInitialized = useRef(false)

  if (!hasGAInitialized.current) {

    ReactGA.initialize(GOOGLE_ANALYTICS_ID, {
      gtagOptions: {
        'debug_mode': true,
        'user_properties': {
          'AB_image_user': findAbTest('AB_image', Number(ABTest)),
          'AB_headline_user': findAbTest('AB_headline', Number(ABTest)),
          'AB_supporting_copy_user': findAbTest('AB_supporting_copy', Number(ABTest)),
          'AB_CTA_user': findAbTest('AB_CTA', Number(ABTest)),
          // 'debug_user_name': 'Oleg 2',
        }
      },
    })
    hasGAInitialized.current = true
  }
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    })
  }, [location])

  const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
  }

  ReactPixel.init(config?.fbPixel || '', undefined, options)

  const { isLoading } = useQuery(
    ['questions'],
    async () => {
      await getSurvey(referenceCode).then((res) => {
        quizStore.setQuestions(res || [])
        return res
      })
    },
    {
      refetchOnWindowFocus: true,
    },
  )

  if (isLoading) {
    return <LoaderPulse />
  }

  const landingData = createLandingData(Number(ABTest))

  return (
    <div
      className={'text-center flex w-full !sm:min-h-screen'}
      style={{
        minHeight: `${height}px`,
      }}
    >
      {/* <MouseFlow />*/}
      <MetaPixel fbPixelId={config?.fbPixel || ''} />
      <img
        height='1'
        width='1'
        className='hidden'
        src={`https://www.facebook.com/tr?id=${config?.fbPixel || ''}&ev=PageView&noscript=1`}
      />
      <Routes>
        <Route path='/' element={<RedirectPage />} />
        <Route path='/results' element={<Results />} />
        <Route path='/results/success' element={<SuccessForm />} />
        <Route path='/survey/:index' element={<Survey />} />
        <Route path='/survey' element={<Survey />} />
        <Route path='/intro' element={<Intro />} />
        <Route path='/gdpr' element={<GDPR />} />
        <Route path={'/subscribe'} element={<Subscribe />} />
        <Route path={'/Join-private-beta'} element={<PrivateBeta />} />
        <Route path={'/share'} element={<Share />} />
        <Route path={'/how-to-reduce'} element={<HowToReduce />} />
        <Route path={'/how-it-works'} element={<HowItWorks />} />
        <Route path={'/join-wait-list'} element={<JoinWaitList />} />
        <Route path={'/take-an-action'} element={<TakeAnAction />} />
        <Route path={'/waitlist'} element={<JoinWaitList />} />
        <Route path={'/clear-cache'} element={<ClearCache />} />
        <Route path={'/introduction'} element={<Introduction isLoading={isLoading} />} />
        <Route path='*' element={<RedirectPage />} />
        <Route path={'/verwelkoming'} element={<LandingPage isLoading={isLoading} landingData={landingData} />} />
      </Routes>
    </div>
  )
}

export default App
