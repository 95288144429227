import React, { memo, useEffect } from 'react'
import './styles.css'
import IntroImage from '../../assets/svg/intro-bg.svg'
import { TextField, Typography } from '@mui/material'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Modal from '../../shared/Modal'
import PrimaryButton from '../../components/PrimaryButton'
import ReactGA from 'react-ga4'
import { IRefUser } from '../../screen.interfaces'
import LogoSvg from '../../assets/svg/logo_intro.svg'
import LogoText from '../../assets/svg/logo-text.svg'
import quizStore from '../../stores/answers'
import { useQuery } from 'react-query'
import { getSurveyRef, postFunnel, postUser } from '../../utils/api'
import LoadImages from '../../components/LoadImages'
import LoaderPulse from '../../components/LoaderPulse'
import ReactPixel from 'react-facebook-pixel'

const Intro = () => {
  const [name, setName] = React.useState<string | null>('')
  const [email, setEmail] = React.useState<string | null>('')
  const [showEmailModal, setShowEmailModal] = React.useState<boolean>(false)
  localStorage.setItem('email', '')
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { state } = useLocation()
  const searchReference = searchParams.get('ref')
  let ref = searchReference || localStorage.getItem('ref') || ''

  if (searchReference) {
    localStorage.setItem('ref', searchReference)
  } else if (!ref) {
    ref = localStorage.getItem('ref') || ''
  }

  useEffect(() => {
    quizStore.clearUserAnswers()
  }, [])

  const { data: refData, isLoading } = useQuery<IRefUser>(
    ['getSurveyRef'],
    () => {
      return getSurveyRef(ref)
    },
    {
      refetchOnMount: true,
      enabled: !!ref,
    },
  )


  const gotoSurvey = async () => {
    if (name) {
      await postUser({
        name,
      })
    }
    setShowEmailModal(true)
  }
  if (isLoading) {
    return <LoaderPulse />
  }

  return (
    <div
      className={
        'w-full h-full min-h-screen flex items-center justify-center bg-no-repeat bg-white bg-cover  '
      }
      style={{
        backgroundImage: `url(${IntroImage})`,
        backgroundColor: '#F5F5F5',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <LoadImages />
      <div className='container flex justify-center relative h-full px-4'>
        <div className='flex flex-col items-center justify-evenly sm:w-2/3 intro-section w-full h-full '>
          <div className='flex flex-col items-start justify-center w-full h-full'>
            <div className={'flex items-center justify-start w-full h-full'}>
              <div className={'w-[24px] h-[34px]'}>
                <img src={LogoSvg} alt='logo' className={'h-full w-full'} />
              </div>
              <div className={'flex justify-center h-full'}>
                <img src={LogoText} alt='logo' className={'w-[75.82px] h-[16.65px]'} />
              </div>
            </div>
            {/* <div className={'intro-title mt-8'}> */}
            {/*   Hello and welcome <br /> to pyrpose.io */}
            {/* </div> */}

            {!ref && (
              <div className={'intro-message pt-10'}>
                We can all do our part to help the climate, the problem is most of us don’t know
                where to start. Pyrpose is here to help you better understand your individual
                emissions and help you to make a difference
              </div>
            )}

            {ref && (
              <div className={'intro-message'}>
                <b>
                  {refData?.name || ''} {refData?.surname}{' '}
                </b>{' '}
                <br /> has <b>{refData?.ratio?.toString().replace('.', ',')}X</b> more CO2 emissions
                than the global average.
                <br />
                <br />
                Are you curious to know your personal emissions? Lets check yours and compare.
              </div>
            )}
          </div>
          <div className={'flex flex-col items-center justify-center w-full h-full'}>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                ReactGA.event({
                  category: 'Intro',
                  action: 'InitSurvey',
                })
                ref ? navigate(`/results?ref=${ref}`, {
                state: {
                answers: state?.answers,
                ref: ref,
              },
              }) : navigate('/results', {
                state: { answers: state?.answers },
              })
                // gotoSurvey()
                //   .then()
                //   .catch((e) => console.error(e))
              }}
              className={'w-full'}
            >
              {/* <TextField */}
              {/*   id='outlined-basic' */}
              {/*   label='Your name' */}
              {/*   variant='outlined' */}
              {/*   color={'primary'} */}
              {/*   value={name} */}
              {/*   onChange={(e) => setName(e.target.value)} */}
              {/*   style={{ marginBottom: '24px' }} */}
              {/*   className={'w-full px-4'} */}
              {/*   sx={{ */}
              {/*     '& .MuiOutlinedInput-root': { */}
              {/*       borderRadius: '15px', */}
              {/*       backgroundColor: '#FFFFFF', */}
              {/*     }, */}
              {/*   }} */}
              {/* /> */}
              <PrimaryButton type='submit'
                             // disabled={!name}
                             className={'!w-full mt-6 h-[61px]'}>
                <Typography
                  style={{ color: '#fff', fontSize: 24, fontWeight: '700', textTransform: 'none' }}
                >
                  Continue to results
                </Typography>
              </PrimaryButton>
            </form>
            <div className={'mt-10 text-[14px] text-left'}>
              By continuing, you agree to our
              <a
                href='https://pyrpose.io/privacy-policy'
                className={'font-bold underline text-[14px]'}
              >
                {' '}
                Privacy Policy{' '}
              </a>
              ,{' '}
              <a
                href='https://pyrpose.io/terms-of-use'
                className={'font-bold underline text-[14px]'}
              >
                Terms of Service
              </a>
              , and{' '}
              <a
                className={'font-bold underline text-[14px]'}
                href='https://survey.pyrpose.io/gdpr'
              >
                {' '}
                GDPR policy
              </a>
            </div>
            <Modal show={showEmailModal}>
              <form
                onSubmit={async (e) => {
                  e.preventDefault()
                  if (email) {
                    ReactGA.event({
                      category: 'Intro',
                      action: 'InitSurvey',
                      label: email,
                    })
                    ReactPixel.track('CompleteRegistration', {
                      email: email,
                    })
                    postFunnel({
                      emailFirstStep: email,
                    }).then()
                    await postUser({
                      email,
                    })
                  }
                  localStorage.setItem('email', email || '')
                  ref ? navigate(`/results?ref=${ref}`, {
                    state: {
                      answers: state?.answers,
                      ref: ref,
                    },
                  }) : navigate('/results', {
                    state: { answers: state?.answers },
                  })
                }}
                className={'flex flex-col items-start justify-between'}
                style={{ maxWidth: '417px' }}
              >
                <div
                  className={'flex items-center justify-between w-full'}
                  style={{ marginBottom: '20px' }}
                >
                  <div className={'share-title'}>✉️</div>
                  <div
                    className={'share-skip'}
                    aria-label='close'
                    onClick={() => {
                      ref ? navigate(`/results?ref=${ref}`, {
                        state: {
                          answers: state?.answers,
                          ref: ref,
                        },
                      }) : navigate('/results', {
                        state: { answers: state?.answers },
                      })
                    }}
                  >
                    skip
                  </div>
                </div>

                <div className={'share-title'}>Before we start</div>
                <div className={'share-message'}>
                  To enable us to notify you of your friends results, <br />
                  please leave your email address
                </div>
                <TextField
                  variant='outlined'
                  color={'primary'}
                  InputProps={{
                    type: 'email',
                  }}
                  placeholder={'Your email address'}
                  className={'w-full'}
                  value={email}
                  style={{ marginBottom: '16px', marginTop: '46px' }}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '15px',
                      backgroundColor: '#FFFFFF',
                    },
                  }}
                />
                <PrimaryButton type='submit' disabled={!email}>
                  <Typography
                    style={{
                      color: '#fff',
                      fontSize: 24,
                      fontWeight: '700',
                      textTransform: 'none',
                    }}
                  >
                    Continue
                  </Typography>
                </PrimaryButton>
              </form>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Intro)
