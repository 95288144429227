import Typography from '@mui/material/Typography'
import { IFootprint, ISurvey } from './screen.interfaces'
import React, { useEffect } from 'react'
import { TextField } from '@mui/material'
import Box from '@mui/material/Box'
import 'animate.css'
import PrimaryButton from './components/PrimaryButton'
import { Markup } from 'interweave'
import Modal from './shared/Modal'
// import { getMachineId } from './utils'
import { useNavigate } from 'react-router-dom'
import SecondaryButton from './components/SecondaryButton'
import { joinPrivateBeta } from './utils/api'
import ReactGA from 'react-ga4'

interface IScreenProps {
  step: ISurvey
  // eslint-disable-next-line
  handleNext: (values: any) => void
  baseEmail?: string
}

const ScreenInfo: React.FC<IScreenProps> = ({ step, handleNext, baseEmail }) => {
  const [showEmailModal, setShowEmailModal] = React.useState<boolean>(false)
  const [email, setEmail] = React.useState<string | null>(baseEmail || null)
  const [footprint, setFootprint] = React.useState<IFootprint | null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchEmail = async () => {
      const result = await localStorage.getItem('footprint')
      if (result) {
        setFootprint(JSON.parse(result) || null)
      }
    }
    fetchEmail()
      .then((r) => r)
      .catch((e) => e)
  }, [])

  return (
    <div className={'flex flex-col justify-between h-full max-sm:min-h-[80vh]'}>
      <div />
      <div className={'flex flex-col items-center justify-center sm:h-1/2'}>
        <div
          className={'w-full flex m-[auto] flex-col items-center sm:gap-10 gap-4 justify-center'}
        >
          <Markup
            // sx={{
            //     '@media (max-width: 768px)': {
            //         fontSize: '40px',
            //         lineHeight: '40px',
            //         marginTop: '40px',
            //         marginBottom:'16px'
            //     }
            // }}
            content={step?.info}
          />
        </div>
      </div>
      <Box className={'py-10'}>
        {step?.id !== 'screenIntroCommunityAccess' && (
          <SecondaryButton next={true} onClick={handleNext} />
        )}

        {step?.id === 'screenIntroCommunityAccess' && (
          <PrimaryButton
            onClick={() => {
              setShowEmailModal(true)
            }}
            next={false}
          >
            <Typography
              style={{ color: '#fff', fontSize: 24, fontWeight: '700', textTransform: 'none' }}
            >
              {footprint
                ? `Reduce my ${footprint?.myEmission?.toString().replace('.', ',')} CO2 mT`
                : 'Reduce my footprint'}
            </Typography>
          </PrimaryButton>
        )}
      </Box>

      <Modal show={showEmailModal}>
        <div className={'flex flex-col max-w-[416px] items-start justify-between'}>
          <div className={'flex items-center justify-between w-full mb-[24px]'}>
            <div className={'share-title'}>➕</div>
            <div
              className={'share-skip'}
              aria-label='close'
              onClick={() => {
                navigate('/results')
              }}
            >
              skip
            </div>
          </div>

          <div className={'share-title'}>Join private beta</div>
          <div className={'share-message  mb-[46px]'}>
            To get access to the private beta - share us your email
          </div>
          <TextField
            variant='outlined'
            color={'primary'}
            placeholder={'Your email address'}
            className={'w-full px-4'}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '15px',
                backgroundColor: '#FFFFFF',
              },
            }}
          />
          <PrimaryButton
            variant='contained'
            disabled={!email}
            onClick={async () => {
              const data = email
                ? {
                    email: email,
                    joinBeta: true,
                  }
                : {
                    joinBeta: true,
                  }
              if (email) {
                localStorage.setItem('email', email)
                ReactGA.event({
                  category: 'joinBeta',
                  action: 'Join Private Beta',
                  label: email,
                })
                // await fetch('https://webhook.site/20596abf-eafb-4f02-90ec-98bd889af79b', {
                //   method: 'POST',
                //   mode: 'cors',
                //   headers: {
                //     'Content-Type': 'application/json',
                //     'device-id': getMachineId(),
                //   },
                //   body: JSON.stringify({
                //     email: email,
                //   }),
                // })
              }
              joinPrivateBeta(data).then()
              navigate('/results', { state: { tooltip: true } })
            }}
            className={'!mt-[17.5px]'}
          >
            <Typography
              style={{ color: '#fff', fontSize: 24, fontWeight: '700', textTransform: 'none' }}
            >
              Continue
            </Typography>
          </PrimaryButton>
        </div>
      </Modal>
    </div>
  )
}

export default ScreenInfo
