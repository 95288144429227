import React, { CSSProperties, MouseEvent, useEffect, useState } from 'react'
import {
  clientNames,
  crossmintModalService,
  CrossmintPayButtonProps,
  crossmintPayButtonService,
  crossmintStatusService,
  mintingContractTypes,
  onboardingRequestStatusResponse,
} from '@crossmint/client-sdk-base'
import { Button } from '@mui/material'
import ReactGA from 'react-ga4'
import { checkCode, getSession, postFunnel } from '../../../utils/api'

// eslint-disable-next-line
const defaultMintConfig: any = {
  type: mintingContractTypes.CANDY_MACHINE,
}

export interface CrossmintPayButtonReactProps extends CrossmintPayButtonProps {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  style?: CSSProperties
  setSessionId: (sessionId: string | null) => void
  accessCode?: string
  handleError: (message: string) => void
  internalClientId?: string
  reducePercentage?: number | null | string,
  money?: number | null | string,
  validateAccessCode?: boolean,
}

export function CrossmintPayButton({
  // eslint-disable-next-line
  className,
  disabled,
  onClick,
  money,
  // eslint-disable-next-line
  style,
  tabIndex,
  // eslint-disable-next-line
  theme = 'dark',
  reducePercentage,
  mintTo,
  emailTo,
  listingId,
  clientId,
  auctionId,
  hideMintOnInactiveClient = false,
  // eslint-disable-next-line
  showOverlay = true,
  mintConfig = defaultMintConfig,
  whPassThroughArgs,
  environment,
  paymentMethod,
  preferredSigninMethod,
  // eslint-disable-next-line
  dismissOverlayOnClick,
  prepay,
  locale = 'en-US',
  currency = 'USD',
  successCallbackURL = '',
  failureCallbackURL = '',
  accessCode,
  setSessionId,
  handleError,
  // eslint-disable-next-line
  internalClientId,
  validateAccessCode = true,
  // eslint-disable-next-line
  ...props
}: CrossmintPayButtonReactProps) {
  const [connecting, setConnecting] = useState(false)
  const [status, setStatus] = useState(onboardingRequestStatusResponse.WAITING_SUBMISSION)

  const { fetchClientIntegration } = crossmintStatusService({
    libVersion: '0.2.7-alpha.22',
    clientId,
    environment,
    auctionId,
    mintConfig,
    setStatus,
    clientName: clientNames.reactUi,
  })

  const { connect } = crossmintModalService({
    clientId,
    showOverlay: false,
    dismissOverlayOnClick: true,
    setConnecting,
    libVersion: '0.1.0',
    environment,
    clientName: clientNames.reactUi,
    locale,
    currency,
    successCallbackURL,
    failureCallbackURL,
  })

  const { shouldHideButton, handleClick } = crossmintPayButtonService({
    onClick,
    connecting,
    paymentMethod,
    locale,
  })

  useEffect(() => {
    if (hideMintOnInactiveClient) {
      fetchClientIntegration()
    }
  }, [status])

  const _handleClick = async (event: MouseEvent<HTMLButtonElement>) => {

    postFunnel({
      pressPurchaseButton: 'TRUE',
      price: money,
      reducedPercentage: reducePercentage,
      usedCode: accessCode,
    }).then()
    const handleConnect = async (res: string) => {
      await connect(
        {
          ...mintConfig,
          sessionId: res,
        },
        mintTo,
        emailTo,
        listingId,
        {
          ...whPassThroughArgs,
          sessionId: res,
        },
        paymentMethod,
        preferredSigninMethod,
        prepay,
      )
    }

    try {
      const res = await getSession()
      if (validateAccessCode) {
        await checkCode(accessCode || '')
          .then((response) => {
            if (res && response) {
              ReactGA.event({
                  category: 'Purchase Code',
                  action: 'Code successfully used',
                  label: 'Code successfully used',
                },
              )
              setSessionId(res)
              handleClick(event, async () => {
                await handleConnect(res)
              })
            }
          })
          .catch((error) => {
            console.log(error)
            handleError(
              error?.response?.data?.message ||
              'An error occurred while checking the access code. Please try again.',
            )
          })
      } else {
        setSessionId(res)
        handleClick(event, async () => {
          await handleConnect(res)
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  if (shouldHideButton({ hideMintOnInactiveClient, status })) {
    return null
  }


  return (
    <>
      <Button
        onClick={_handleClick}
        tabIndex={tabIndex}
        variant='contained'
        disabled={disabled}
        className={'w-full !sm:mt-10 !font-bold'}
        style={validateAccessCode ? {
          backgroundColor: '#fff',
          color: '#000',
          borderRadius: '16px',
          height: '64px',
          fontSize: '24px',
          lineHeight: '29px',
          textTransform: 'none',
          boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.25)',
          border: '1px solid #000',
          opacity: disabled ? 0.5 : 1,
        } : {
          backgroundColor: '#000',
          color: '#fff',
          opacity: disabled ? 0.5 : 1,
          borderRadius: '16px',
          height: '64px',
          fontSize: '24px',
          lineHeight: '29px',
          textTransform: 'none',
          boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.25)',
          border: '1px solid #000',
        }}
      >
        {validateAccessCode ? 'Continue' : 'Purchase'}
      </Button>
    </>
  )
}
