import React from 'react'

interface ErrorTooltipProps {
  errorTooltip: string
}
const ErrorTooltip: React.FC<ErrorTooltipProps> = ({
  errorTooltip,
}) => {
  return (
    <div
      className={
        'flex animate__fadeIn flex-row items-center justify-start sm:justify-between w-full px-2 py-4 bg-white rounded-[16px] shadow-md'
      }
      style={{
        border: '1px solid #FFB6A6',
      }}
    >
      <div className={'text-[20px] px-2 h-full'}>❌</div>
      <div className='flex flex-col px-2'>
        <div className={'text-[16px] font-bold text-start'}>{errorTooltip}</div>
      </div>
    </div>
  )
}

export default ErrorTooltip