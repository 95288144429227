import React, { memo, type FC } from 'react'
import Field0 from '../assets/img/FootballField/field-0.webp'
import Field10 from '../assets/img/FootballField/field-10.webp'
import Field20 from '../assets/img/FootballField/field-20.webp'
import Field30 from '../assets/img/FootballField/field-30.webp'
import Field40 from '../assets/img/FootballField/field-40.webp'
import Field50 from '../assets/img/FootballField/field-50.webp'
import Field60 from '../assets/img/FootballField/field-60.webp'
import Field70 from '../assets/img/FootballField/field-70.webp'
import Field80 from '../assets/img/FootballField/field-80.webp'
import Field90 from '../assets/img/FootballField/field-90.webp'
import Field100 from '../assets/img/FootballField/field-100.webp'
import Trees10 from '../assets/img/FootballField/trees-10.webp'
import Trees20 from '../assets/img/FootballField/trees-20.webp'
import Trees30 from '../assets/img/FootballField/trees-30.webp'
import Trees40 from '../assets/img/FootballField/trees-40.webp'
import Trees50 from '../assets/img/FootballField/trees-50.webp'
import Trees60 from '../assets/img/FootballField/trees-60.webp'
import Trees70 from '../assets/img/FootballField/trees-70.webp'
import Trees80 from '../assets/img/FootballField/trees-80.webp'
import Trees90 from '../assets/img/FootballField/trees-90.webp'
import Trees100 from '../assets/img/FootballField/trees-100.webp'
import FullField from '../assets/img/FootballField/fullField.webp'
const LoadImages: FC = () => {
  return (
    <div className={'absolute top-0 right-0 h-[1px] w-[1px]'}>
      <img src={Field0} alt='field-0' />
      <img src={Field10} alt='field-10' />
      <img src={Field20} alt='field-20' />
      <img src={Field30} alt='field-30' />
      <img src={Field40} alt='field-40' />
      <img src={Field50} alt='field-50' />
      <img src={Field60} alt='field-60' />
      <img src={Field70} alt='field-70' />
      <img src={Field80} alt='field-80' />
      <img src={Field90} alt='field-90' />
      <img src={Field100} alt='field-100' />
      <img src={Trees10} alt='trees-10' />
      <img src={Trees20} alt='trees-20' />
      <img src={Trees30} alt='trees-30' />
      <img src={Trees40} alt='trees-40' />
      <img src={Trees50} alt='trees-50' />
      <img src={Trees60} alt='trees-60' />
      <img src={Trees70} alt='trees-70' />
      <img src={Trees80} alt='trees-80' />
      <img src={Trees90} alt='trees-90' />
      <img src={Trees100} alt='trees-100' />
      <img src={FullField} alt='full-field' />
    </div>
  )
}

export default memo(LoadImages)
