import React, { memo, useEffect, useState } from 'react'
import './styles.scss'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import PyrposeLogo from '../../assets/svg/pyrpose-logo.svg'
import Typography from '@mui/material/Typography'
import { Header } from '../../components/Header'
import { ISurvey } from '../../screen.interfaces'
import Factory from '../../assets/svg/factory_info.svg'
import ScreenInfo from '../../ScreenInfo'
import BlurBG from '../../assets/svg/blurbg.svg'
import ReactGA from 'react-ga4'
import { getSurveyTakeAnAction, postUser } from '../../utils/api'
import { useQuery } from 'react-query'
import LoaderPulse from '../../components/LoaderPulse'

const TakeAnAction = () => {
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(0)
  // eslint-disable-next-line
  const [skipped, setSkipped] = useState(new Set<number>())
  const [steps, setSteps] = useState<ISurvey[]>([])
  // eslint-disable-next-line
  const [visible, setVisible] = useState<boolean>(false)
  const email = localStorage.getItem('email') || ''
  const { data, isLoading } = useQuery(
    ['getSurveyTakeAnAction'],
    () => {
      return getSurveyTakeAnAction(null)
    },
    {
      refetchOnMount: true,
    },
  )

  useEffect(() => {
    if (data && data.screens) {
      setSteps(data?.screens)
    }
  }, [data])

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleSkip = () => {
    if (steps[activeStep].id === 'screenIntroCommunityAccess') {
      navigate('/results')
    }
    if (steps[activeStep].id === 'screenIntroHowPurposeWorks') {
      postUser({ seenLastScreen: true }).then()
    }
    ReactGA.event({
      category: 'TakeAnAction',
      action: 'Skip TakeAnAction',
      label: 'Skip Take An Action',
    })
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  const color = steps[activeStep]?.color || '#C9E7F4'

  const getInfoColor = () => {
    if (steps[activeStep]) {
      switch (steps[activeStep]?.id) {
        case 'screenIntroForMe':
          return 'rgba(40, 106, 132, 0.4)'
        case 'screenIntroHowPurposeWorks':
          return 'rgba(31, 125, 69, 0.4)'
        case 'screenIntroCommunityAccess':
          return 'rgba(95, 35, 116, 0.4)'
        default:
          return '#C9E7F4'
      }
    } else return '#C9E7F4'
  }
  if (isLoading) {
    return <LoaderPulse />
  }

  return (
    <Box
      className={'flex sm:flex-row flex-col sm:h-screen w-full'}
      sx={{
        backgroundColor: color,
      }}
    >
      <Box className={'sm:w-4/12 sm:h-full md:w-3/12 flex flex-col'}>
        <Box
          className={'sm:py-10 sm:px-4 sm:h-full flex sm:justify-between justify-center '}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: visible ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0.01)',
            backdropFilter: visible ? 'blur(0)' : 'blur(58px)',
          }}
        >
          <div className={'sm:flex hidden'}>
            <img
              src={PyrposeLogo}
              alt={'PyrposeLogo'}
              className={'lg:w-1/3 sm:w-1/2 w-1/4'}
              style={{ height: 'auto' }}
            />
          </div>
          <div className={'flex flex-col justify-center h-full'}>
            <Typography
              className={'sm:text-left text-center'}
              sx={{
                color: '#fff',
                fontSize: '96px',
                fontWeight: '700',
                maxWidth: '100%',
                lineHeight: '88px',
                '@media (max-width: 768px)': {
                  fontSize: '40px',
                  lineHeight: '40px',
                  marginTop: '40px',
                  marginBottom: '16px',
                },
              }}
            >
              {steps[activeStep]?.category}
            </Typography>
          </div>
        </Box>
        <div className={'sm:flex hidden'}>
          <img src={Factory} alt={'Illustration'} style={{ width: '100%', height: 'auto' }} />
        </div>
      </Box>

      <Box
        className={'sm:w-8/12 w-full flex md:w-9/12 justify-center relative h-full'}
        sx={{
          backgroundColor: getInfoColor(),
          backgroundImage: `url(${BlurBG})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Box className={'max-w-[462px] px-4 w-full h-full flex justify-between flex-col'}>
          <Header
            handleSkip={handleSkip}
            handleBack={handleBack}
            activeStep={activeStep}
            steps={steps}
            isInfo={true}
          />
          <ScreenInfo
            step={steps[activeStep]}
            key={steps[activeStep]?.id}
            handleNext={handleSkip}
            baseEmail={email}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default memo(TakeAnAction)
