import React from 'react'
import ONU from '../../../../assets/img/onu.png'
import OnuAuthor from '../../../../assets/img/OnuAuthor.png'
import QuoteItem from './QuoteItem'
import Billie from '../../../../assets/img/billie.png'
import WorldWideAuthor from '../../../../assets/img/world-wide-author.png'
import Concern from '../../../../assets/img/concern.png'

const quotes = [
  {
    quote: '“Era of global warming has ended, the era of global boiling has arrived”',
    authorName: 'Antonio Guiterres <br/> UN Secretary General',
    mainImage: ONU,
    authorImage: OnuAuthor,
  },
  {
    quote: '“We don’t have to waste a bunch of stuff, solutions already exist and I want to be as eco-friendly as possible.”',
    authorName: 'Billie Eilish <br/> on climate friendly music tours',
    authorImage: WorldWideAuthor,
  },
  {
    quote: '“Climate change is hitting the most vulnerable parts of the world”',
    authorName: 'Concern WorldWide Report',
    authorImage: Billie,
    mainImage: Concern,

  },
]
const QuoteList = () => {
  return (
    <div className={'flex flex-col items-center mx-4 my-[80px] gap-[40px]'}>
      {
        quotes.map((item, index) => (
          <QuoteItem key={index} {...item} />
        ))
      }
    </div>
  )
}

export default QuoteList
