import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { TextField } from '@mui/material'
import { CrossmintPayButton } from './PayButton'
import config from '../../../config'

interface ValidateModalProps {
  setShowValidateModal: (value: boolean) => void
  setShowEmailModal: (value: boolean) => void
  accessCode: string | null
  setSessionId: (value: string | null) => void
  setErrorTooltip: (value: string) => void
  reducePercentage: number
  money: number
  machineId: string | null
}
const ValidateModal:React.FC<ValidateModalProps> = ({
  setShowValidateModal,
  setShowEmailModal,
  accessCode,
  setSessionId,
  setErrorTooltip,
  reducePercentage,
  money,
  machineId,
}) => {

  return (
    <div
      className={
        'absolute top-0 left-0 w-full h-full bg-[#FFFFFF60] backdrop-blur-[59px] z-[1250] flex items-center justify-center'
      }
    >
      <div
        className={'absolute top-4 left-4 z-[9999] cursor-pointer'}
        onClick={() => setShowValidateModal(false)}
      >
        <CloseIcon />
      </div>
      <div className={'w-[90%] sm:w-[50%] max-w-[452px] p-4 rounded-lg'}>
        <div className={'text-[20px] text-start pb-[24px] text-[#000000]'}>
          To continue, please enter your personal invitation code
        </div>
        <Formik
          initialValues={{ accessCode: accessCode || '' }}
          onSubmit={async (values) => console.log(values)}
          validationSchema={Yup.object().shape({
            accessCode: Yup.string()
              .min(3, 'Invitation code need to be at least 3 symbols')
              .max(16, 'Invitation code need to be maximum 16 symbols')
              .required('Required'),
          })}
        >
          {({ errors, touched, values, handleChange, handleBlur }) => (
            <Form className={'flex flex-col items-center justify-center w-full'}>
              <TextField
                name='accessCode'
                variant='outlined'
                className={'w-full'}
                autoFocus={true}
                value={values.accessCode}
                label={'Invitation code'}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!values.accessCode && touched.accessCode && !!errors.accessCode}
                helperText={!!values.accessCode && touched.accessCode && errors.accessCode}
                placeholder={'Invitation code'}
              />
              <div className={'h-4'} />
              <CrossmintPayButton
                disabled={touched.accessCode && !!errors.accessCode}
                clientId={config?.crossmintClientId || ''}
                setSessionId={setSessionId}
                accessCode={values.accessCode}
                reducePercentage={reducePercentage}
                money={money.toFixed(0)}
                mintConfig={{
                  type: 'erc-721',
                  totalPrice: `${money.toFixed(0)}`,
                  _price: money.toFixed(0),
                  _quantity: 1,
                  _clientId: config.crossmintClientId,
                  userId: machineId,
                  internalClientId: machineId,
                }}
                handleError={(message: string) => setErrorTooltip(message)}
                environment={config.env === 'production' ? 'production' : 'staging'}
                whPassThroughArgs={{
                  internalClientId: machineId,
                  totalPrice: Number(money.toFixed(0)),
                }}
              />
              <div className={'flex flex-row items-start justify-start w-full pt-4'}>
                <div className={'text-[14px] text-start text-[#000000]'}>Have no code?</div>
                <div className={'w-1'} />
                <div
                  className={
                    'text-[14px] text-start font-bold text-[#000000] underline cursor-pointer'
                  }
                  onClick={() => {
                    setShowEmailModal(true)
                  }}
                >
                  Subscribe to Waitlist
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default ValidateModal