import React from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { Button, Checkbox, TextField, Typography } from '@mui/material'
import IntroImage from '../../assets/svg/intro-bg.svg'
import ReactGA from 'react-ga4'

export const Subscribe: React.FC = () => {
  const [checked, setChecked] = React.useState(false)
  return (
    <MailchimpSubscribe
      url='https://pyrpose.us9.list-manage.com/subscribe/post?u=a6c8785b71a9ff6a68dd75120&amp;id=2e9385a848&amp;f_id=00e719e1f0'
      render={({
        subscribe,
      }: {
        subscribe: (arg0: { EMAIL: string; FNAME: string; LNAME: string }) => void
      }) => (
        <div
          className={
            'w-full min-h-screen h-full flex items-center justify-center bg-no-repeat bg-white bg-cover '
          }
          style={{
            backgroundImage: `url(${IntroImage})`,
          }}
        >
          <div className='container flex justify-center relative h-full   px-4'>
            <div className='flex flex-col items-center justify-evenly sm:w-2/3 md:w-1/3 w-full h-full'>
              <form
                onSubmit={(e) => {
                  ReactGA.event({
                    category: 'Subscribe Page',
                    action: 'Subscribe',
                    label: `Subscribe ${e.currentTarget.email.value}`,
                  })
                  e.preventDefault()
                  subscribe({
                    EMAIL: e.currentTarget.email.value,
                    FNAME: e.currentTarget.fname.value,
                    LNAME: e.currentTarget.surname.value,
                  })
                }}
                className={'flex flex-col items-center justify-center w-full h-full'}
              >
                <div className={'flex flex-col mb-12'}>
                  <Typography
                    variant='body1'
                    className={'!font-bold !text-[32px] text-center mt-4 '}
                  >
                    🥑 pyrpose notes
                  </Typography>
                  <Typography variant='body1' className={'!text-[16px]  text-center px-6 '}>
                    some notes on what pyrpose is up to and some opining on climate solutions
                  </Typography>
                </div>
                <TextField
                  id='email'
                  label='Email'
                  variant='outlined'
                  type='email'
                  color={'primary'}
                  placeholder={'Your email'}
                  className={'w-full px-4 '}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '15px',
                      backgroundColor: '#FFFFFF',
                    },
                  }}
                />
                <div className={'h-4'} />
                <TextField
                  id='fname'
                  label='Name'
                  type='text'
                  variant='outlined'
                  color={'primary'}
                  placeholder={'Your name'}
                  className={'w-full px-4'}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '15px',
                      backgroundColor: '#FFFFFF',
                    },
                  }}
                />
                <div className={'h-4'} />
                <TextField
                  id='surname'
                  label='Surname'
                  type='text'
                  variant='outlined'
                  color={'primary'}
                  placeholder={'Your surname'}
                  className={'w-full px-4 '}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '15px',
                      backgroundColor: '#FFFFFF',
                    },
                  }}
                />
                <div className={'flex flex-row items-center pt-8 w-full'}>
                  <Checkbox
                    onChange={(e) => setChecked(e.target.checked)}
                    checked={checked}
                    color='primary'
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    className={' mt-6 -pr-2'}
                  />
                  <Typography variant='body1' className={'!text-[12px]  text-left px-6 text-[] '}>
                    I agree to the{' '}
                    <a
                      href={'https://pyrpose.io/terms-of-use'}
                      target={'_blank'}
                      className={' underline '}
                      rel='noreferrer'
                    >
                      Terms of Service
                    </a>{' '}
                    and{' '}
                    <a
                      href={'https://pyrpose.io/privacy-policy'}
                      target={'_blank'}
                      className={'underline'}
                      rel='noreferrer'
                    >
                      Privacy Policy
                    </a>
                  </Typography>
                </div>
                <Button
                  variant='contained'
                  type='submit'
                  disabled={!checked}
                  className={'w-full px-4 mt-6 h-[61px]'}
                  sx={{
                    borderRadius: '15px',
                    backgroundColor: '#000000',
                    color: '#FFFFFF',
                    fontWidth: 'bold',
                    fontSize: 24,
                    marginTop: '24px',
                  }}
                >
                  Subscribe
                </Button>
                <div className={'w-full pt-4 '}></div>
              </form>
            </div>
          </div>
        </div>
      )}
    />
  )
}
