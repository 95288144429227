import React from 'react'
import { Markup } from 'interweave'


interface HeaderBlockProps {
  handleClick: () => void
  img: string
  headline: string
  CTAText: string
  supportingText: string
}

const HeaderBlock: React.FC<HeaderBlockProps> = ({
  handleClick,
  img,
  headline,
  CTAText,
  supportingText,
}) => {
  return (
    <div className={'w-full flex flex-col items-center'}
         style={{
           backgroundImage: `url(${img})`,
           backgroundPosition: 'top',
           backgroundRepeat: 'no-repeat',
           backgroundSize: 'contain',
         }}>
      <div className={'mt-[60%] w-full flex'}>
        <div
          className=' mx-4 p-8 bg-gradient-to-b from-zinc-100 to-white rounded-2xl shadow shadow-inner backdrop-blur-md flex-col justify-start items-center gap-6 inline-flex'>
          <div className='self-stretch text-center text-stone-900 text-[32px] font-bold leading-[35px]'>{headline}
          </div>
          <div className='w-16 h-[0px] border border-black'></div>
          <Markup className='self-stretch text-center text-black text-base font-normal leading-snug' content={supportingText} />
          <div
            className='self-stretch h-16 px-6 py-4 bg-zinc-900 rounded-2xl justify-center items-center gap-2 inline-flex'
            onClick={handleClick}>
            <div className='text-center text-green-300 text-[24px] font-bold'>{CTAText}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderBlock
