import Hobo from '../assets/img/abtest/hobo.jpg'
import Windmill from '../assets/img/abtest/windmill.png'
import SunnyAmsterdam from '../assets/img/abtest/sunny-amsterdam.png'
import FoggyAmsterdam from '../assets/img/abtest/foggy-amsterdam.png'
import WomenInFacemask from '../assets/img/abtest/women-in-facemask.png'
import config from '../config'

export const formatDate = (date: Date) => {
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0') // January is 0!
  const year = date.getFullYear()

  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`
}

const CurrentAbTest = config.currentAbTest


const tests: {
  [key: string]: {
    'default_option': string,
    'default_ab_key': string,
    'ab': { [key: number]: string },
    'ab_keys': { [key: number]: string },
  }
} = {
  'AB_image': {
    'default_ab_key': 'not selected',
    'default_option': Hobo,
    'ab': {
      1: Hobo,
      2: Windmill,
      3: SunnyAmsterdam,
      4: FoggyAmsterdam,
      5: WomenInFacemask,
    },
    'ab_keys': {
      1: 'Hobo',
      2: 'Windmill',
      3: 'Sunny Amsterdam',
      4: 'Foggy Amsterdam',
      5: 'Women in face mask',
    },
  },
  'AB_headline': {
    'default_ab_key': 'not selected',
    'default_option': 'Know your numbers, to act better',
    'ab': {
      1: 'Know your numbers, to act better',
      2: 'Take a 2 minute carbon footprint quiz',
      3: 'Carbon Check:  What’s your carbon footprint?',
      4: 'Do you know your carbon footprint?',
      5: 'Doing good for the climate starts with you',
    },
    'ab_keys': {
      1: 'Headline Know your numbers',
      2: 'Headline 2 minute',
      3: 'Headline Carbon Check',
      4: 'Headline Do you know',
      5: 'Headline Doing good',
    },
  },
  'AB_supporting_copy': {
    'default_ab_key': 'not selected',
    'default_option': 'Prepare to be shocked:\n' +
      'the average person in the Netherlands produces a staggering 81% more pollution than the global norm.\n' +
      '\n' +
      'It’s time to face the hard truth about our environmental impact.',
    'ab': {
      1: 'Prepare to be shocked:\n' +
        'the average person in the Netherlands produces a staggering 81% more pollution than the global norm. \n' +
        '\n' +
        'It’s time to face the hard truth about our environmental impact.',
      2: 'Hold onto your seats: we\'re about to unveil the second version of the text, which delves deeper and clarifies further.\n' +
        '\n' +
        'It’s the moment of revelation for what lies ahead in our content journey.',
      3: 'Brace yourselves: The third iteration is on the horizon, ready to provide an even richer narrative. It’s time to face the hard truth about our environmental impact.',
      4: 'Get ready for another twist: Version four is set to unfold, bringing new perspectives and insights. It’s time to face the hard truth about our environmental impact.',
      5: 'Anticipate the next leap: The fifth version is gearing up to captivate with its revamped content and structure It’s time to face the hard truth about our environmental impact.',
    },
    'ab_keys': {
      1: 'AB_supporting_copy shocked',
      2: 'AB_supporting_copy 2',
      3: 'AB_supporting_copy 3',
      4: 'AB_supporting_copy 4',
      5: 'AB_supporting_copy 5',
    },
  },
  'AB_CTA': {
    'default_ab_key': 'not selected',
    'default_option': 'Know your numbers',
    'ab': {
      1: 'Know your numbers',
      2: 'Check your carbon',
      3: 'Take Quiz Now',
      4: '2 minute quiz',
      5: 'Pollution or solution?',
    },
    'ab_keys': {
      1: 'AB_CTA Know your numbers',
      2: 'AB_CTA Check your carbon',
      3: 'AB_CTA Take Quiz Now',
      4: 'AB_CTA 2 minute quiz',
      5: 'AB_CTA Pollution or solution?',
    },
  },
}


export const findAbTest = (abTest: string, variant: number) => {
  const test = tests[abTest]
  if (!test) return 'Test not found'

  if (abTest == CurrentAbTest) {
    return tests[abTest].ab_keys[variant]
  }
  return tests[abTest].default_ab_key
}
export const createLandingData = (variant: number) => {
  const result: { [key: string]: string } = {}

  for (const testKey in tests) {
    if (testKey === CurrentAbTest) {
      result[testKey] = tests[testKey].ab[variant]
    } else {
      result[testKey] = tests[testKey].default_option
    }
  }

  return result
}


export const AbTestEnum: { [key: string]: string } = {
  1: 'Hobo',
  2: 'Windmill',
  3: 'Sunny Amsterdam',
  4: 'Foggy Amsterdam',
  5: 'Women in face mask',
}