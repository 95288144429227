import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import LoaderPulse from '../../components/LoaderPulse'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga4'
import IntroductionBackground from '../../assets/svg/introduction-background.svg'

interface IntroductionProps {
  isLoading: boolean
}

const Introduction: React.FC<IntroductionProps> = ({ isLoading }) => {
  const [isTouched, setIsTouched] = React.useState<boolean>(false)
  const height = window?.visualViewport?.height || window.innerHeight
  const navigate = useNavigate()

  const handleNext = async () => {
    if (isLoading) {
      setIsTouched(true)
    } else {
      setIsTouched(false)
      ReactPixel.track('pre-survey', {
        contentName: 'pre-survey',
        contentCategory: 'pre-survey',
      })
      ReactGA.event({
          category: 'pre-survey',
          action: 'pre-survey',
          label: 'pre-survey',
        },
      )

      const ref = await localStorage.getItem('ref') || ''
      ref ? navigate(`/survey?ref=${ref}&step=0`) : navigate('/survey?step=0')
    }
  }

  useEffect(() => {
    if (!isLoading && isTouched) {
      handleNext().then(r => r)
    }
  }, [isLoading, isTouched])

  return (
    isTouched && isLoading ? <LoaderPulse /> :
      <div
        onClick={handleNext}
        className={'w-full h-full min-h-full flex-col flex items-center justify-center max-sm:p-[16px]'}
        style={{
          minHeight: `${height}px`,
          backgroundImage: `url(${IntroductionBackground})`,
          backgroundPosition: 'top',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPositionY: 'center',
        }}>
        <div className={'flex flex-col items-center justify-center sm:!w-1/2 md:!w-1/3 '}>
          <div className='justify-start items-start gap-2 inline-flex'>
            <div className=' text-center text-black text-[32px] font-bold leading-[35px]'>The annual average
              pollution per person is equal to:
            </div>
          </div>
          <div className=' flex justify-between w-full items-center flex-row'>
            <div className=' text-center text-black text-[64px] w-[30%] font-bold'>🧊</div>
            <div className='text-center text-black text-[64px] w-[30%] font-bold'>🌲</div>
            <div className=' text-center text-black text-[64px] w-[30%] font-bold'>🐋</div>
          </div>
          <div className=' flex justify-between w-full items-start flex-row'>
            <div className=' flex flex-col justify-start items-center gap-2.5 w-[30%]'>
              <div className='self-stretch text-center text-black text-2xl font-extrabold'>15</div>
              <div className=' text-center text-black text-xs font-normal leading-[18px]'>square meters of sea
                ice loss
              </div>
            </div>
            <div className='grow shrink basis-0 opacity-50  text-gray-400 text-2xl font-extrabold'>or</div>
            <div className=' flex flex-col justify-start items-center gap-2.5 w-[30%]'>

              <div className='self-stretch text-center text-black text-2xl font-extrabold'>359</div>
              <div className='self-stretch text-center text-black text-xs font-normal leading-[18px]'>cut down trees
              </div>
            </div>
            <div className='grow shrink basis-0 opacity-50  text-gray-400 text-2xl font-extrabold'>or</div>
            <div className=' flex flex-col justify-start items-center gap-2.5 w-[30%]'>
              <div className='self-stretch text-center text-black text-2xl font-extrabold'>19%</div>
              <div className='self-stretch text-center text-black text-xs font-normal leading-[18px]'>percent of the
                humpback whale weight
              </div>
            </div>
          </div>
          <div className='self-stretch pb-10 flex-col justify-start items-center gap-10 flex'>
            <div className='self-stretch text-center text-black text-xl font-bold leading-relaxed'>You’ll be amazed by
              what
              you discover!
            </div>
          </div>
          <div
            className='w-full h-16 px-6 py-4 bg-zinc-900 rounded-2xl justify-center items-center gap-2 inline-flex cursor-pointer'
            onClick={handleNext}>
            <div className='text-center text-green-300 text-2xl font-bold'>2 minute discovery</div>
          </div>
        </div>
      </div>
  )
}

export default Introduction
