import React from 'react'

interface FeedbackItemProps {
  image: string
  text: string
  name: string
}
const FeedbackItem:React.FC<FeedbackItemProps> = ({
  image,
  text,
  name,
}) => {
  return (
    <div className="w-full flex-col justify-start items-center gap-4 inline-flex relative">
      <img alt={'person'} src={image} />
      <div className="self-stretch h-[75px] flex-col justify-start items-start gap-4 flex ">
        <div className="absolute top-[130px] left-[10%] text-[#DFDFDF50] text-[96px] font-bold leading-[88px]">“</div>
        <div className="self-stretch px-8 text-black text-base text-center font-normal leading-snug">{text}</div>
        <div className="self-stretch text-center text-black text-xs font-bold">{name}</div>
        <div className="absolute right-[10%] bottom-[-30px] text-[#DFDFDF50] rotate-180  text-[96px] font-bold ">“</div>
      </div>
    </div>
  )
}

export default FeedbackItem
