import React, { useCallback } from 'react'
import { AnimatedNumber } from './components/AnimatedValue'
import { useLocation, useNavigate } from 'react-router-dom'
import LogoSvg from '../../assets/svg/logo_intro.svg'
import LogoText from '../../assets/svg/logo-text.svg'
import Share from '../../assets/svg/share.svg'
import CompareResultsModal from '../CompareResultsModal'
import ShareLinkModal from '../ShareLinkModal'
import FootballFields from './components/FootballFields'
import ReactGA from 'react-ga4'
import { postUser } from '../../utils/api'
import { commaSeparator } from './utils/calculation'
import SuccessEmissionLine from './components/SuccessEmissionLine'
import FeedbackSuccessModal from '../../components/FeedbackSuccessModal'
import { treesToField } from '../../constants/values'

const height = window.innerHeight
const SuccessForm = () => {
  const [showCompareModal, setShowCompareModal] = React.useState<boolean>(false)
  const [showLinkModal, setShowLinkModal] = React.useState<boolean>(false)
  const [feedbackModal, setFeedbackModal] = React.useState<boolean>(true)
  const { state } = useLocation()
  const navigate = useNavigate()
  const email = localStorage.getItem('email')
  const result = state || {}
  const handleShare = () => {
    ReactGA.event({
      category: 'ShareLinkModal',
      action: 'Open ShareLinkModal',
    })
    !email ? setShowCompareModal(true) : setShowLinkModal(true)
    postUser({
      sharePressed: true,
    }).then()
  }

  const handleCompareResultsModalSkip = () => {
    ReactGA.event({
      category: 'CompareResultsModal',
      action: 'Skip CompareResultsModal',
    })
    setShowCompareModal(false)
    setShowLinkModal(true)
  }
  const handleShareLinkModalSkip = () => {
    setShowLinkModal(false)
    ReactGA.event({
      category: 'ShareLinkModal',
      action: 'Skip ShareLinkModal',
    })
    navigate('/take-an-action')
  }

  const activeComponents = useCallback(() => {
    return (
      <div>
        <div>
          <div
            className={
              'sm:text-[32px] text-[20px] mt-4 text-center font-bold sm:leading-[35px] leading-[24px] max-sm:mb-2'
            }
          >
            Your reduction equals planted trees:
          </div>
          <div className={'w-full'}>
            <AnimatedNumber value={state?.reduceField * treesToField} duration={100} />
          </div>
        </div>
        <div className={'flex flex-row flex-wrap gap-2 w-full items-end justify-center'}>
          <FootballFields fields={state?.reduceFields} />
        </div>
      </div>
    )
  }, [result])

  const activeRightComponent = useCallback(() => {
    return (
      <div className={'flex flex-col items-center justify-center w-full'}>
        <SuccessEmissionLine reduceValue={state?.reduceValue} />
      </div>
    )
  }, [])

  return (
    <div
      className={`w-full h-[${height}px] flex sm:flex-row flex-col max-sm:justify-between relative`}
      style={{
        backgroundImage: 'linear-gradient(180deg, #FFFFFF 0%, #F0FFF3 100%)',
      }}
    >
      <div
        className={
          'flex flex-col container items-center justify-center h-full px-4 sm:w-[72%] min-w-[72%] w-full max-sm:overflow-y-scroll'
        }
      >
        <div
          className={
            'flex flex-row w-full justify-between max-sm:justify-start items-center pt-6 px-2'
          }
        >
          <div className={'flex items-center justify-start max-sm:hidden'}>
            <div className={'w-[24px] h-[34px]'}>
              <img src={LogoSvg} alt='logo' className={'h-full w-full'} />
            </div>
            <div className={'flex justify-center h-full'}>
              <img src={LogoText} alt='logo' className={'w-[75.82px] h-[16.65px]'} />
            </div>
          </div>
          <div
            className={'flex flex-row items-start sm:justify-center justify-between max-sm:w-full'}
          >
            <div
              onClick={handleShare}
              className={'flex flex-row items-center justify-center cursor-pointer ml-4'}
            >
              <div className={'text-[16px] mx-2 '}>Share</div>
              <img src={Share} alt={'Share'} />
            </div>
          </div>
        </div>
        <div
          className={
            'h-full max-sm:overflow-y-scroll w-full flex-col items-center flex sm:w-2/3 w-full px-4 sm:justify-center max-sm:pb-[50px]'
          }
        >
          <div
            className={
              'text-[40px] text-center font-bold sm:leading-[35px] leading-[24px] my-4 text-[#BCCBCF]'
            }
          >
            Well done
          </div>
          <span className={'text-[16px] text-center'}>
            You reduce{' '}
            <span className={'font-bold text-[16px]'}>
              {commaSeparator(state?.reduceEmission.toFixed(2)) + ' CO2 '}
            </span>
            metric tones
          </span>
          {activeComponents()}
        </div>
      </div>
      <div
        className={
          'sm:w-[28%] min-w-[28%] w-full sm:h-full max-sm:sticky max-sm:bottom-0 max-sm  bg-white px-8 flex items-center flex-col sm:justify-center max-sm:pb-6 justify-end max-sm:rounded-t-[25px] max-sm:shadow-2xl'
        }
      >
        {activeRightComponent()}
      </div>
      <CompareResultsModal show={showCompareModal} onSkip={handleCompareResultsModalSkip} />
      <ShareLinkModal show={showLinkModal} onSkip={handleShareLinkModalSkip} />
      <FeedbackSuccessModal show={feedbackModal} onCancel={() => {
        setFeedbackModal(false)
      }} />
    </div>
  )
}

export default SuccessForm
