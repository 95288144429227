import React from 'react'
import TreesIntroduction from '../../../assets/img/trees-introduction.png'
const TreesBlock = () => {
  return (
    <div className={'w-full flex flex-col px-4 bg-white py-24'}>
      <div className="w-full text-center text-stone-900 text-[32px] pb-8 font-bold leading-[35px]">You know what Co2 is but do you know the equivalent in trees?</div>
      <img src={TreesIntroduction} alt='TreesIntroduction' className={
        'w-full h-full bg-contain'
      } />
      <div className="w-full text-center text-stone-900 text-[20px] font-bold leading-normal">needed to cover 1 Co2 metric tone emissions annually </div>
    </div>
  )
}

export default TreesBlock
