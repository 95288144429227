import React from 'react'

interface IHeader {
  handleSkip: () => void
  handleBack: () => void
  activeStep: number
  isInfo?: boolean
  // eslint-disable-next-line
  steps: any[]
}

export const Header = ({ handleSkip, handleBack, activeStep, isInfo, steps }: IHeader) => {
  return (
    <div className={'w-full flex justify-between items-center flex-row pt-4 pb-4 sm:pt-12'}>
      <div className='w-2/10 flex justify-start align-center'>
        {activeStep !== 0 ? (
          <button disabled={activeStep === 0} onClick={handleBack} className='text-md'>
            <span
              className='flex flex-row'
              style={{
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '22px',
              }}
            >
              back
            </span>
          </button>
        ) : (
          <div className={'w-[30px]'} />
        )}
      </div>
      <div
        className='w-6/10 flex justify-center align-center gap-1 flex-row transition
            duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110'
      >
        {steps?.map((step, index) => {
          return activeStep === index ? (
            <div
              key={step.id}
              className={'flex justify-center align-center h-[6px] '}
              style={{
                width: '48px',
                borderRadius: '28px',
                height: '8px',
                backgroundColor: 'white',
              }}
            />
          ) : (
            <div key={step.id} className={'flex align-center w-2 h-2 rounded-full bg-black'} />
          )
        })}
      </div>
      <div className='w-2/10 flex justify-center align-center'>
        <button
          color='primary'
          onClick={handleSkip}
          disabled={isInfo && false}
        >
          <span
            className='flex flex-row'
            style={{
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '22px',
            }}
          >
            skip
          </span>
        </button>
      </div>
    </div>
  )
}
