import React from 'react'
import Person1 from '../../../../assets/img/person-1.png'
import Person2 from '../../../../assets/img/person-2.png'
import Person3 from '../../../../assets/img/person-3.png'
import FeedbackItem from './FeedbackItem'

const feedbacks = [
  {
    image: Person1,
    text: '“I now know what changes I need to make in my life to support the climate.”',
    name: '-Sarah, Netherlands',
  },
  {
    image: Person2,
    text: '“I had no idea eating red meat was contributing to climate change!”',
    name: '-Hendrik, Netherlands',
  },
  {
    image: Person3,
    text: '“I chose to join pyrpose as I could clearly see how I was fighting climate change”',
    name: '-Christina, Switzerland',
  },
]
const FeedbackList = () => {
  return (
    <div className={'w-full px-4 flex flex-col items-center justify-center gap-10 py-[80px] bg-cyan-50'}>
      <div className="w-full text-center text-stone-900 text-[32px] font-bold leading-[35px]">User feedback</div>
      {
        feedbacks.map((feedback, index) => (
            <FeedbackItem key={`key:${index}:feedback`} {...feedback} />
          ),
        )
      }
    </div>
  )
}

export default FeedbackList
