import React from 'react'
import Typography from '@mui/material/Typography'
import { IEmissionLine } from '../../../screen.interfaces'
import { commaSeparator } from '../utils/calculation'
import { InfoCard, InfoModal } from './index'
import { useSwipeable } from 'react-swipeable'
import { useSpring, animated } from '@react-spring/web'
import CoverEmissionButton from './CoverEmissionButton'

interface EmissionLineProps {
  average: number
  myEmission: string
  activeStep: number | null
  setActiveStep: (step: number | null) => void
  refState: any
  showFriends: number
  emissionLine: IEmissionLine[]
  setStep: (step: number) => void
}

type ComparisonResult = {
  value: string | number
  label: string
}

export const compareEmissions = (number: number, average: number): ComparisonResult => {
  const diff = commaSeparator(Math.abs(number / average).toFixed(2))
  return {
    value: diff + 'X',
    label: number > average ? 'higher' : number < average ? 'less' : 'the same',
  }
}

const EmissionLine: React.FC<EmissionLineProps> = (props) => {
  const { /* average, myEmission, refState,*/ emissionLine, showFriends, setStep, activeStep, setActiveStep } = props
  const emissions = emissionLine.filter((item) => Number(item.value) > 0)
  const modalEmissionArray: any[] = emissionLine.filter((item) => Number(item.value) > 0)
  const maxSteps = modalEmissionArray.length
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (activeStep !== null && activeStep < maxSteps - 1) {
        setActiveStep(activeStep ? activeStep + 1 : 1)
      }
    },
    onSwipedRight: () => {
      if (activeStep !== null && activeStep > 0) {
        setActiveStep(activeStep - 1)
      }
    },
    trackMouse: true,
    trackTouch: true,
    preventScrollOnSwipe: true,
  })


  return (
    <animated.div
      className={'w-full sm:pt-[80px] pt-[40px] sm:pb-[64px] pb-[24px] flex items-center justify-center animate__fadeIn flex-col animated '}>
      <div className={'flex flex-col items-center justify-center  my-4 w-full px-4'}>
        {showFriends !== 2 ?
          <div className={'sm:text-[32px] text-[20px] font-bold sm:leading-[38px] leading-[24px]  '}>
            The sources of your {
            showFriends === 1 ? 'friend`s' : null
          } emissions:
          </div>
          : null}
        {showFriends !== 2 && (
          <>
            <div className={'flex flex-row justify-between w-full gap-1 mb-2 mt-6 rounded-[12px]'}>
              {emissions.map((item, index) => {
                if (parseInt(item.value) > 0) {
                  return (
                    <div
                      key={index}
                      className={'flex flex-col items-center '}
                      style={{ width: `${item.value}%` }}
                    >
                      <div className={'flex flex-col items-center w-full'}>
                        <Typography
                          variant='body1'
                          className={'!text-[12px] text-center mt-4'}
                          style={{
                            maxWidth: '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {parseInt(item.value) > 3 ? item.value + '%' : item.value}
                        </Typography>
                        <div
                          className={'w-full h-[24px] shadow-md'}
                          style={{
                            backgroundColor: item.color,
                            borderRadius:
                              index === 0
                                ? '4px 0 0 4px'
                                : index === emissions.length - 1
                                  ? '0 4px 4px 0'
                                  : '0',
                          }}
                        />
                      </div>
                    </div>
                  )
                }
                return null
              })}
            </div>
            <div className={'flex flex-row justify-center flex-wrap w-full gap-3  rounded-[12px]'}>
              {emissionLine.map((item, index) => {
                if (parseInt(item.value) > 0) {
                  return (
                    <div key={index} className={'flex flex-row justify-around items-center'}>
                      <div
                        className='rounded-full h-[8px] w-[8px] mr-2'
                        style={{
                          backgroundColor: item.color,
                        }}
                      ></div>
                      <Typography
                        variant='body1'
                        className={'!text-[12px] text-center mt-4'}
                        style={{
                          maxWidth: '100%',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {item.label}
                      </Typography>
                    </div>
                  )
                }
                return null
              })}
            </div>
          </>
        )}
        <div className={'h-6'} />
        <CoverEmissionButton setStep={setStep} showFriends={showFriends} big={true} />
      </div>
      {/* <div className={'flex flex-row flex-1 justify-between flex-grow overflow-auto w-full gap-3 mt-6 px-4 max-sm:max-w-[100vw]'} */}
      {/*      style={{ WebkitOverflowScrolling: 'touch', scrollbarWidth: 'none', msOverflowStyle: 'none' }}> */}
      {/*   { */}
      {/*     modalEmissionArray.map((item, index) => { */}
      {/*       if (item) return ( */}
      {/*         <> */}
      {/*           <InfoCard activeCard={item} key={`${index}infoCard`} */}
      {/*                     setStep={() => setActiveStep(index)} /> */}
      {/*         </> */}
      {/*       ) */}
      {/*     }) */}
      {/*   } */}
      {/* </div> */}
      {/* <div */}
      {/*   className={activeStep !== null ? 'fixed flex  items-center justify-center top-0 bottom-0 right-0 left-0 bg-[#4E515CB2] sm:z-[200] ' : 'hidden'} */}
      {/*   onScroll={(e) => { */}
      {/*     e.preventDefault() */}
      {/*     e.stopPropagation() */}
      {/*   } */}
      {/*   } */}
      {/*   onScrollCapture={(e) => { */}
      {/*     e.preventDefault() */}
      {/*     e.stopPropagation() */}
      {/*   } */}
      {/*   } */}
      {/*   onClick={(e) => { */}
      {/*     if (e.target === e.currentTarget) { */}
      {/*       setActiveStep(null) */}
      {/*     } */}
      {/*   }}> */}
      {/*   { */}
      {/*     activeStep !== null && ( */}
      {/*       <InfoModal handlers={handlers} step={activeStep} maxSteps={maxSteps} */}
      {/*                  showFriends={showFriends} setStep={(step) => setStep(step)} */}
      {/*                  back={() => setActiveStep(null)} activeCard={modalEmissionArray[activeStep]} /> */}
      {/*     ) */}
      {/*   }</div> */}
    </animated.div>
  )
}


export default EmissionLine
