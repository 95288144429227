import React from 'react'
import { CompanyList } from '../../Results/components'
import Impact from '../../../assets/img/impact.png'
import SwissStartup from '../../../assets/img/swiss-startup.png'
import TopTier from '../../../assets/img/top-tier.png'
import Whale from '../../../assets/img/whale.png'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import BgButton from '../../../assets/img/bg-button.jpg'

const partners = [
  {
    icon: Impact,
  },
  {
    icon: SwissStartup,
  },
  {
    icon: TopTier,
  },
  {
    icon: Whale,
  },
]


interface FooterProps {
  handleClick?: () => void
}

const Footer: React.FC<FooterProps> = ({
  handleClick,
}) => {
  return (
    <div className={' '}>
      <div className={'flex flex-col  w-full pb-[50px] pt-[64px] px-4 items-center justify-center'}>
        <div className='text-center text-black sm:text-[32px] text-[24px] font-bold leading-[35px] pb-[40px]'>Partners:</div>
        <div
          className={'flex flex-wrap flex-grow sm:flex-row w-full gap-4 gap-y-4 gap-x-14 items-center justify-center '}>
          {
            partners.map((partner, index) => (
              <div className={'flex items-center justify-center'} key={`key:${index}:partners`}>
                <img src={partner.icon} alt={'partners'} />
              </div>
            ))
          }
        </div>
      </div>
      {handleClick &&
        <div className='w-full px-4 flex-col justify-center items-center flex py-24' style={{
          backgroundImage: `url(${BgButton})`,
          backgroundRepeat: 'repeat',
          backgroundSize: 'cover',

        }}>
          <div
            onClick={handleClick}
            className='self-stretch h-16 bg-zinc-900 rounded-2xl justify-center items-center inline-flex cursor-pointer'>
            <div className='text-center text-green-300 text-2xl font-bold'>Take two minutes now</div>
          </div>
        </div>
      }
      <CompanyList />
    </div>
  )
}

export default Footer
