import React, { useEffect, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  HeaderBlock,
  IntroductionBlock,
  QuoateList,
  TreesBlock,
  DataBlock,
  Footer,
  FeedbackList,
  KevinPyrpose,
} from './components'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
import { abtestImages } from './abtest/helpers'

interface LandingPageProps {
  isLoading: boolean
  landingData: any
}

const LandingPage: React.FC<LandingPageProps> = ({
  isLoading,
  landingData,
}) => {
  const navigate = useNavigate()
  const [isTouched, setIsTouched] = React.useState<boolean>(false)
  const blocksRef = useRef({})
  const ABTest = localStorage.getItem('abtest') || ''

  const handleIntersection = (entries: any) => {
    entries.forEach((entry: any) => {
      if (entry.isIntersecting) {
        const blockName = entry.target.getAttribute('data-blockname')
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (blocksRef.current[blockName]) return // if the block was already tracked, ignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        blocksRef.current[blockName] = true

        ReactGA.event({
          category: 'Scrolling',
          action: 'Scrolled to block',
          label: blockName,
        })
        if (Object.keys(blocksRef.current).length === 7) {
          ReactGA.event({
            category: 'Scrolling',
            action: 'Scrolled through entire page',
          })
        }
      }
    })
  }

  const observer = new IntersectionObserver(handleIntersection, {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  })

  useEffect(() => {
    const blocks = document.querySelectorAll('[data-blockname]')
    blocks.forEach(block => observer.observe(block))
    return () => {
      blocks.forEach(block => observer.unobserve(block))
    }
  }, [observer])

  useEffect(() => {
    const timers = [
      setTimeout(() => {
        ReactGA.event({
          category: 'User Engagement',
          action: 'Time on Page',
          label: '15 seconds',
        })
      }, 15000),
      setTimeout(() => {
        ReactGA.event({
          category: 'User Engagement',
          action: 'Time on Page',
          label: '30 seconds',
        })
      }, 30000),
      setTimeout(() => {
        ReactGA.event({
          category: 'User Engagement',
          action: 'Time on Page',
          label: '45 seconds',
        })
      }, 45000),
    ]

    return () => {
      timers.forEach(timer => clearTimeout(timer))
    }
  }, [])
  const handleNavigate = async () => {
    if (isLoading) {
      setIsTouched(true)
    } else {
      setIsTouched(false)
      ReactPixel.track('pre-survey', {
        contentName: 'pre-survey',
        contentCategory: 'pre-survey',
      })
      ReactGA.event({
          category: 'pre-survey',
          action: 'pre-survey',
          label: 'pre-survey',
        },
      )

      const ref = await localStorage.getItem('ref') || ''
      ref ? navigate(`/survey?ref=${ref}&step=0`) : navigate('/survey?step=0')
    }
  }

  useEffect(() => {
    if (!isLoading && isTouched) {
      handleNavigate().then(r => r)
    }
  }, [isLoading, isTouched])
  const AbImg = abtestImages[ABTest.toString() || '1']

  return (
    <div className={'w-full  flex items-center justify-center flex-col overflow-hidden'}>
      <div className={'sm:w-[512px]'}>
        <div data-blockname='HeaderBlock'>
          <HeaderBlock handleClick={handleNavigate} img={landingData.AB_image}
                       headline={landingData.AB_headline} CTAText={landingData.AB_CTA}
                       supportingText={landingData.AB_supporting_copy}
          />
        </div>
        <div data-blockname='QuoateList'>
          <QuoateList />
        </div>
        <div data-blockname='IntroductionBlock'>
          <IntroductionBlock handleClick={handleNavigate} />
        </div>
        <div data-blockname='TreesBlock'>
          <TreesBlock />
        </div>
        <div data-blockname='DataBlock'>
          <DataBlock handleClick={handleNavigate} />
        </div>
        <div data-blockname='FeedbackList'>
          <FeedbackList />
        </div>
        <div data-blockname='KevinPyrpose' className={'w-full'}>
          <KevinPyrpose />
        </div>
        <div data-blockname='Footer'>
          <Footer handleClick={handleNavigate} />
        </div>
      </div>
    </div>
  )
}

export default LandingPage
